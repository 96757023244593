<div class="file_upload">
  @if (file) {
    <div class="uploaded_files">
      <div class="content">
        <div class="file_type_icon">
          <img src="assets/icons/file_icon.svg">
          <div class="file_type_wrap"
               [ngStyle]="{'background': getFileTypeColor(file)}"
          >
            {{ getFileType(file) }}
          </div>
        </div>
      </div>
      <div class="filename_and_size">
        <div class="file_name">{{ file.name }}</div>
        <div class="file_size">{{ file.size }}</div>
      </div>
      <div class="delete_icon" (click)="removeFileAdded()">
        <img src="assets/icons/delete_icon.svg" alt="delete icon">
      </div>
    </div>
  } @else {
    <div
      class="upload-container"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      [class.dragging]="isDragging"
      [ngClass]="{
        'file-required-error':error?.hasError
      }"
    >
      <div class="upload-content">
        <div class="cloud-icon">
          <img src="assets/icons/upload_folder_icon.svg" alt="upload_icon">
        </div>
        <input
          #fileInput
          type="file"
          (change)="onFileSelected($event)"
          [multiple]="false"
          class="hidden-input"
        />
        <div class="text_and_upload_btn">
          <div class="upload-text">Click to upload or drag and drop</div>
          <button class="upload-button" (click)="fileInput.click()">
            <img src="assets/icons/upload_icon.svg" /> Upload File
          </button>
          <div class="upload-text error">{{error?.errorMsg}}</div>
        </div>


      </div>
    </div>
  }
</div>


