<div class="container">
  @if (!showUploadForm) {
    <div class="igm_upload_partial_success">
      <div class="igm_upload_partial_success_icon">
        <img src="assets/icons/upload_error.svg" alt="partial_success" />
      </div>
      <div class="notifications">
        @if (igmDeskFileUploadRes.successCount > 0) {
          <div class="notification">
            <img src="assets/icons/success.svg" alt="partial_success" />
            <div class="message">
              <span class="success_msg">{{ igmDeskFileUploadRes.successCount }} Container</span>
              uploaded successfully.
            </div>
          </div>
        }

        @if (igmDeskFileUploadRes.failureCount > 0) {
          <div class="notification">
            <img src="assets/icons/file_upload_warning.svg" alt="partial_success" />
            <div class="message_and_sub_text">
              <div class="message">
                <span class="error_msg">{{ igmDeskFileUploadRes.failureCount }} Container</span>
                information not uploaded.
              </div>
              <span class="sub_text">All errors are listed in the file below. Download to review details.</span>
            </div>

          </div>
        }
        <div class="warning_message">
          Warning: You will not be able to download the error file again or from any other page.
        </div>
      </div>
      <div class="buttons">
        @if (igmDeskFileUploadRes?.errorCsv) {
          <button class="primary_button" (click)="downloadErrorFile()">
            <img src="assets/icons/save_icon.svg">Download Error File
          </button>
        }
        <button class="secondary_button" (click)="renderUploadForm()">Upload More File</button>
      </div>
    </div>
  } @else {
    <app-file-upload
      (fileSelected)="getSelectedFile($event)"
      [allowedFileTypes]="allowedFileTypes"
    ></app-file-upload>
    <form [formGroup]="uploadForm" class="igm_upload_form">
      <div class="radio_button_with_label">
        <label class="required_field_label">Upload Type</label>
        <div class="radio-group" (change)="setUploadType($event)">
          <label>
            <input type="radio" name="uploadType" value="{{UploadType.IGM.key}}" checked /> {{ UploadType.IGM.label }}
          </label>
          <label>
            <input type="radio" name="uploadType" value="{{UploadType.CSV.key}}" /> {{ UploadType.CSV.label }}
          </label>
          <label>
            <input type="radio" name="uploadType"
                   value="{{UploadType.SCAN_LIST.key}}" /> {{ UploadType.SCAN_LIST.label }}
          </label>
        </div>
      </div>

      @if (uploadType !== UploadType.SCAN_LIST.key) {
        <div class="form-row">
          <div class="form-group">
            <label class="required_field_label" for="viaNumber">VIA Number</label>
<!--            <input type="text" placeholder="Enter via number" formControlName="viaNumber" name="viaNumber"-->
<!--                   id="viaNumber"-->
<!--                   [ngClass]="{-->
<!--                  form_error:-->
<!--                    uploadForm.get('viaNumber')?.invalid &&-->
<!--                    uploadForm.get('viaNumber')?.touched &&-->
<!--                    uploadForm.get('viaNumber')?.errors-->
<!--                }"-->
<!--            >-->
            <app-autocomplete-dropdown
              id="viaNumber"
              name="viaNumber"
              formControlName="viaNumber"
              [placeholder]="'Enter VIA Number'"
              [apiUrl]="'v1/vessel-port-train-details/list'"
              [selectFields]="['id','viaNo']"
              [whereFields]="['viaNo']"
              [limit]="10"
              [orderBy]="{ viaNo: 'asc' }"
              [filterByKey]="'viaNo'"
              [bindLabel]="'viaNo'"
              [bindValue]="'id'"
              [displayColumns]="[
                        { key: 'viaNo', header: 'VIA Number' }
                    ]"
              [ngClass]="{
                  form_error:
                    uploadForm.get('viaNumber')?.invalid &&
                    uploadForm.get('viaNumber')?.touched &&
                    uploadForm.get('viaNumber')?.errors
                }"
            >
            </app-autocomplete-dropdown>
            <div
              class="form_validation_error"
              *ngIf="
                  uploadForm.get('viaNumber')?.invalid &&
                  uploadForm.get('viaNumber')?.touched
                "
            >
              <small *ngIf="uploadForm.get('viaNumber')?.errors?.['required']"
              >VIA Number is required</small
              >
            </div>
          </div>

          <div class="form-group">
            <label class="required_field_label" for="portOfDischargeId">Port Of Discharge</label>
            <app-autocomplete-dropdown
              id="portOfDischargeId"
              name="portOfDischargeId"
              formControlName="portOfDischargeId"
              [placeholder]="'Enter Port Of Discharge'"
              [apiUrl]="'v1/locations/list'"
              [selectFields]="['id', 'name','locationType']"
              [whereFields]="['name']"
              [limit]="10"
              [orderBy]="{ name: 'asc' }"
              [filterByKey]="'name'"
              [bindLabel]="'name'"
              [bindValue]="'id'"
              [displayColumns]="[
                        { key: 'name', header: 'Name' },
                        { key: 'locationType', header: 'Location Type' }
                    ]"
              [ngClass]="{
                  form_error:
                    uploadForm.get('portOfDischargeId')?.invalid &&
                    uploadForm.get('portOfDischargeId')?.touched &&
                    uploadForm.get('portOfDischargeId')?.errors
                }"
            >
            </app-autocomplete-dropdown>
            <div
              class="form_validation_error"
              *ngIf="
                  uploadForm.get('portOfDischargeId')?.invalid &&
                  uploadForm.get('portOfDischargeId')?.touched
                "
            >
              <small *ngIf="uploadForm.get('portOfDischargeId')?.errors?.['required']"
              >Port Of Discharge is required</small
              >
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group">
            <label class="required_field_label" for="primaryCustomer">Primary Customer</label>
            <app-autocomplete-dropdown
              id="primaryCustomer"
              name="primaryCustomer"
              formControlName="primaryCustomer"
              [placeholder]="'Enter Primary Customer'"
              [apiUrl]="'v1/customers/list'"
              [selectFields]="['id', 'name','code','customerType']"
              [whereFields]="['name','code']"
              [limit]="10"
              [orderBy]="{ name: 'asc' }"
              [filterByKey]="'name'"
              [bindLabel]="'name'"
              [bindValue]="'id'"
              [displayColumns]="[
                        { key: 'name', header: 'Customer Name' },
                        { key: 'code', header: 'Customer Code' },
                        { key: 'customerType', header: 'Customer Type' }
                    ]"
              [ngClass]="{
                  form_error:
                    uploadForm.get('primaryCustomer')?.invalid &&
                    uploadForm.get('primaryCustomer')?.touched &&
                    uploadForm.get('primaryCustomer')?.errors
                }"
              (suggestionSelected)="autoPopulateFields($event)"
            >
            </app-autocomplete-dropdown>
            <div
              class="form_validation_error"
              *ngIf="
                  uploadForm.get('primaryCustomer')?.invalid &&
                  uploadForm.get('primaryCustomer')?.touched
                "
            >
              <small *ngIf="uploadForm.get('primaryCustomer')?.errors?.['required']"
              >Primary Customer is required</small
              >
            </div>
          </div>
          <div class="form-group">
            <label class="" for="secondaryCustomer">Secondary Customer</label>
            <app-autocomplete-dropdown
              id="secondaryCustomer"
              name="secondaryCustomer"
              formControlName="secondaryCustomer"
              [placeholder]="'Enter Secondary Customer'"
              [apiUrl]="'v1/customers/list'"
              [selectFields]="['id', 'name','code','customerType']"
              [whereFields]="['name','code']"
              [limit]="10"
              [orderBy]="{ name: 'asc' }"
              [filterByKey]="'name'"
              [bindLabel]="'name'"
              [bindValue]="'id'"
              [displayColumns]="[
                        { key: 'name', header: 'Customer Name' },
                        { key: 'code', header: 'Customer Code' },
                        { key: 'customerType', header: 'Customer Type' }
                    ]"
            >
            </app-autocomplete-dropdown>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group">
            <label class="required_field_label" for="jobProvidedBy">Job Provided by</label>
            <app-autocomplete-dropdown
              id="jobProvidedBy"
              name="jobProvidedBy"
              formControlName="jobProvidedBy"
              [placeholder]="'Enter Job Provided by'"
              [apiUrl]="'v1/customers/list'"
              [selectFields]="['id', 'name','code','customerType']"
              [whereFields]="['name','code']"
              [limit]="10"
              [orderBy]="{ name: 'asc' }"
              [filterByKey]="'name'"
              [bindLabel]="'name'"
              [bindValue]="'id'"
              [displayColumns]="[
                        { key: 'name', header: 'Customer Name' },
                        { key: 'code', header: 'Customer Code' },
                        { key: 'customerType', header: 'Customer Type' }
                    ]"
            >
            </app-autocomplete-dropdown>
            <div
              class="form_validation_error"
              *ngIf="
                  uploadForm.get('jobProvidedBy')?.invalid &&
                  uploadForm.get('jobProvidedBy')?.touched
                "
            >
              <small *ngIf="uploadForm.get('jobProvidedBy')?.errors?.['required']"
              >Job Provided By is required</small
              >
            </div>
          </div>
        </div>
      }
    </form>
    <div class="footer">
      <div class="divider"></div>
      <div class="content">
        <div class="action_btn">
          <button class="secondary_button" (click)="close()">Cancel</button>
          <button (click)="onSubmit()" class="primary_button">Upload</button>
        </div>
      </div>
    </div>
  }
</div>
