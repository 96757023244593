import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgClass, NgIf, NgStyle } from "@angular/common";

@Component({
  selector: "app-file-upload",
  standalone: true,
  imports: [
    NgStyle,
    NgClass,
    NgIf
  ],
  templateUrl: "./file-upload.component.html",
  styleUrl: "./file-upload.component.scss"
})
export class FileUploadComponent {
  isDragging = false;
  file: File | undefined;
  @Output() fileSelected = new EventEmitter<File>();
  error: Object = { hasError: false, errorMsg: "" };
  @Input() allowedFileTypes: string[] = [];

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;

    const files = event.dataTransfer?.files;
    if (files) {
      this.handleFiles(Array.from(files));
    }
  }

  onFileSelected(event: Event): void {
    const element = event.target as HTMLInputElement;
    const files = element.files;
    if (files) {
      this.handleFiles(Array.from(files));
    }
  }

  private handleFiles(files: File[]): void {
    if (!files.length)
      return;
    console.log("Uploaded files:", files);
    const selectedFile = files[0];
    if (this.allowedFileTypes.length &&
      !(this.allowedFileTypes.includes(selectedFile?.type) || this.allowedFileTypes.includes(this.getFileType(selectedFile))) ) {
      this.setError(true, "Invalid file type");
      return;
    }
    this.file = selectedFile;
    this.fileSelected.emit(this.file);
  }

  setError(hasError: boolean, errorMsg: string): void {
    this.error = { hasError, errorMsg };
  }

  getFileTypeColor(file: File): string {
    const type = this.getFileType(file);
    switch (type) {
      case "CSV":
        return "rgba(76, 163, 13, 1)"; // Green
      case "PNG":
      case "JPG":
      case "JPEG":
        return "rgba(255, 105, 46, 1)"; // Orange
      case "PDF":
        return "rgba(227, 27, 84, 1)"; // Red
      default:
        return "rgba(16, 98, 185, 1)"; // Default Gray
    }
  }

  getFileType(file: File): string {
    if (!file) return "";
    const parts = file?.type.split("/");
    return parts.length > 1 ? parts[1].toUpperCase() : file?.name?.split(".")[1].toString().toUpperCase();
  }

  removeFileAdded() {
    this.file = undefined;
    this.fileSelected.emit(this.file);
  }
}
