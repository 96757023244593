import { Component,  
  ComponentRef,
  EventEmitter,
  Input,
  Output,
  Type, 
  ViewChild, 
  ViewContainerRef} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SvgIconComponent } from "../svg-icon/svg-icon.component";

@Component({
  selector: 'app-item-selector-panel',
  standalone: true,
  imports: [FormsModule, CommonModule, SvgIconComponent],
  templateUrl: './item-selector-panel.component.html',
  styleUrl: './item-selector-panel.component.scss'
})
export class ItemSelectorPanelComponent {
  items = [
    { id: '1', name: '214255637/3435/1', description: 'Details about Item 1', status: 'Active' },
    { id: '2', name: '214255637/3436/4', description: 'Details about Item 2', status: 'Inactive' },
    { id: '3', name: '214255637/3437/3', description: 'Details about Item 3', status: 'Active' },
  ];

  searchQuery: string = '';
  selectedItem: any = null;


  @ViewChild('rightPaneContent', { read: ViewContainerRef, static: true })
  rightPaneContent!: ViewContainerRef;

  get addedItems() {
    return this.items;
  }

  ngOnInit() {
    console.log(this.addedItems);
  }

  handleSearch() {
    console.log('Searching for:', this.searchQuery);
  }

  addItem() {
    const newItem = {
      id: (this.items.length + 1).toString(),
      name: `New Item ${this.items.length + 1}`,
      description: 'Details about new item',
      status: 'Pending',
    };
    this.items.push(newItem);
    console.log('Added:', newItem);
  }

  selectItem(item: any) {
    this.selectedItem = item;
  }

  removeSelectedItem() {
    this.items = this.items.filter((i) => i.id !== this.selectedItem.id);
    this.selectedItem = null;
  }

  loadComponent(component: Type<any>, data?: Partial<any>): ComponentRef<any> {
    this.rightPaneContent.clear();
    const componentRef = this.rightPaneContent.createComponent(component);

    if (componentRef.instance) {
      if (data) {
        Object.assign(componentRef.instance, data);
      }
    }
    return componentRef;
  }
}
