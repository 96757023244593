<div class="container">
  <div class="header header_with_back_button">
    <app-back-button></app-back-button>
    <span>
        @if (readOnlyMode) { View } @else if(masterEditingEnabled) { Edit } @else
        {Create New} Trailer
      </span>
  </div>

  <div class="container-content">
    <form [formGroup]="masterForm">
      <div class="section">
        <div class="head">Basic Information</div>
        <div class="fields">
          <div class="grid-container">
            <div class="form-group">
              <label for="trailerNumber" class="required_field_label" #formField
              >Trailer Number</label
              >
              <input
                id="trailerNumber"
                name="trailerNumber"
                formControlName="trailerNumber"
                type="text"
                placeholder="Enter trailer number"
                required
                autocomplete="trailerNumber"
                [ngClass]="{
                    form_error:
                      masterForm.get('trailerNumber')?.invalid &&
                      masterForm.get('trailerNumber')?.touched &&
                      masterForm.get('trailerNumber')?.errors
                  }"
              />
              <div
                class="form_validation_error"
                *ngIf="
                    masterForm.get('trailerNumber')?.invalid &&
                    masterForm.get('trailerNumber')?.touched
                  "
              >
                <small *ngIf="masterForm.get('trailerNumber')?.errors?.['required']"
                >Trailer Number is required</small
                >
              </div>
            </div>
            <div class="form-group">
              <label for="vendorId">Vendor</label>
              <app-autocomplete-dropdown
                id="vendorId"
                name="vendorId"
                formControlName="vendorId"
                [placeholder]="'Enter Vendor'"
                [apiUrl]="'v1/vendors/list'"
                [selectFields]="['id', 'name', 'code']"
                [limit]="10"
                [orderBy]="{ name: 'asc' }"
                [filterByKey]="'name'"
                [bindLabel]="'name'"
                [bindValue]="'id'"
                [disable]="masterForm.disabled"
                (suggestionSelected)="masterDataResponse && masterDataResponse.vendorId = $event.id"
              >
              </app-autocomplete-dropdown>
            </div>
            <div class="form-group">
              <label for="vehicleTypeId">Vehicle Type</label>
              <input
                id="vehicleTypeId"
                name="vehicleTypeId"
                formControlName="vehicleTypeId"
                type="text"
                placeholder="Choose Vehicle Type"
              />
            </div>
          </div>
        </div>
      </div>




    </form>
  </div>
  <div class="footer_actions">
    <ng-container *ngIf="readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Back
      </button>
    </ng-container>
    <ng-container *ngIf="!readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Cancel
      </button>
      <button
        type="button"
        class="primary_button"
        (click)="submitMasterForm()"
      >
        @if(masterEditingEnabled){ Update }@else { Save }
      </button>
    </ng-container>
  </div>

</div>
