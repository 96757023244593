<div class="search_overlay">
  <div class="search_bar">
    <input #searchInput type="text" name="search_nav_items" placeholder="Search..." (input)="onSearch($event.target.value)">
    <img src="assets/icons/search_icon.svg" class="search_icon">
  </div>

  <div class="expanded_nav_items">

    <ul class="tree">

      @for (parent of filteredMenuItems; track parent.id) {
        <li>
          <div class="parent_nav_item">{{ parent.displayLabel }}</div>
          <ul>
            @for (child of parent.childResources; track child.id) {
              <li>
                <a class="sub_nav_item" [routerLink]="child.resourceProperties?.url"
                   [ngClass]="{
                    'active': makeNavItemActive(child.resourceProperties.url)
                  }"
                >
                  <div class="content">
                    <div class="text">{{ child.displayLabel }}</div>
                  </div>
                </a>
              </li>
            }
          </ul>
        </li>
      }
    </ul>

  </div>
</div>
