<div class="form-container-main">
    <!--<header class="header section-header">
        <div class="header-content">
            <div class="header-text">
                <div class="section-title">Container Details</div>
                <div class="section-subtitle">Fill in Container(s) information to gate-in</div>
            </div>
        </div>
    </header>-->

    <div class = "content-section card-layout">
        <div class="header container-header">
            <div class="header-content">
                <div class="header-text">
                    <div class="section-title">Container {{ (index + 1) }}:
                        <span *ngIf="containerNo; else placeholder">{{ containerNo }}</span>
                        <ng-template #placeholder>Not available</ng-template>
                    </div>
                </div>
                <div class="button-actions">
                    <button class="btn fetch-eir-btn text">
                        <app-svg-icon class="image-icon"
                            [path]="'assets/icons/download_cloud_icon.svg'"
                        ></app-svg-icon>
                        Fetch EIR
                    </button>
                    <button class="btn fetch-eir-btn text" *ngIf="index > 0" (click)="removeForm()">
                        <app-svg-icon class="image-icon"
                            [path]="'assets/icons/delete_icon.svg'"
                        ></app-svg-icon>
                        Delete
                    </button>
                </div>
            </div>
            <div class="divider"></div>
        </div>
        <div class="form-container">
            <form [formGroup]="containerForm">
                <div class="grid-container" [style.grid-template-areas]="gridTemplateAreas">
                <div class="form-item containerNoItem" *ngIf="isFieldInGrid('containerNo')">
                    <label for="containerNo" class="form-label">Container No<span *ngIf="isFieldRequired('containerNo')" class="required">*</span></label>

                    <input *ngIf="!allowContainerSearch" type="text" id="containerNo" formControlName="containerNo" class="form-input" placeholder="Enter Container No."/>

                    <app-autocomplete-dropdown
                    *ngIf="allowContainerSearch"
                        id="containerNo"
                        name="containerNo"
                        formControlName="containerNo"
                        class = "autocomplete-dropdown"
                        [placeholder]="'Select'"
                        [apiUrl]="containerSearchEndpoint"
                        [selectFields]="['id', 'containerNo', 'isoCode', 'containerSize', 'containerType']"
                        [whereFields]="['containerNo']"
                        [limit]="10"
                        [orderBy]="{ containerNo: 'asc' }"
                        [filterByKey]="'containerNo'"
                        [bindLabel]="'containerNo'"
                        [bindValue]="'containerNo'"
                        [allowCustomInput] = "true"
                        [displayColumns]="[
                            { key: 'containerNo', header: 'Container No' },
                            { key: 'isoCode', header: 'ISO Code' },
                            { key: 'containerSize', header: 'Size' },
                            { key: 'containerType', header: 'Type' },
                        ]"
                        [additionalFilters]="containerFilters"
                        [disable]="containerForm.disabled"
                        (suggestionSelected) = "onContainerSelection($event)"
                        (customInputSelected) = "onEnteringContainerNo($event)"
                        >
                    </app-autocomplete-dropdown>
                    <div class="form_validation_error" [ngClass]="{ form_error: (containerForm.get('containerNo')?.invalid && containerForm.get('containerNo')?.touched)}">
                        <small *ngIf="containerForm.get('containerNo')?.errors?.['invalidValue']">Container No is required</small>
                    </div>
                </div>
                <div class="form-item accountHolderItem" *ngIf="isFieldInGrid('accountHolderId')">
                    <label for="accountHolderId" class="form-label">Account Holder<span *ngIf="isFieldRequired('accountHolderId')" class="required">*</span></label>
                    <app-autocomplete-dropdown
                        id="accountHolderId"
                        name="accountHolderId"
                        formControlName="accountHolderId"
                        class = "autocomplete-dropdown"
                        [placeholder]="'Select'"
                        [apiUrl]="'v1/customers/list'"
                        [selectFields]="['id', 'name', 'code']"
                        [whereFields]="['name', 'code']"
                        [limit]="10"
                        [orderBy]="{ name: 'asc' }"
                        [filterByKey]="'name'"
                        [bindLabel]="'name'"
                        [bindValue]="'id'"
                        [displayColumns]="[
                            { key: 'name', header: 'Name' },
                            { key: 'code', header: 'Code' },
                        ]"
                        [disable]="containerForm.disabled"
                        >
                    </app-autocomplete-dropdown>
                    <div class="form_validation_error" [ngClass]="{ form_error: (containerForm.get('accountHolderId')?.invalid && containerForm.get('accountHolderId')?.touched)}">
                        <small *ngIf="containerForm.get('accountHolderId')?.errors?.['required']">Account Holder is required</small>
                    </div>
                </div>

                <div class="form-item lineAgentItem" *ngIf="isFieldInGrid('lineAgentId')">
                    <label for="lineAgentId" class="form-label">Line Agent<span *ngIf="isFieldRequired('lineAgentId')" class="required">*</span></label>
                    <app-autocomplete-dropdown
                        id="lineAgentId"
                        name="lineAgentId"
                        formControlName="lineAgentId"
                        class = "autocomplete-dropdown"
                        [placeholder]="'Select'"
                        [apiUrl]="'v1/customers/list'"
                        [selectFields]="['id', 'name', 'customerType']"
                        [whereFields]="['name']"
                        [limit]="10"
                        [orderBy]="{ name: 'asc' }"
                        [filterByKey]="'name'"
                        [bindLabel]="'name'"
                        [bindValue]="'id'"
                        [disable]="containerForm.disabled"
                        [displayColumns]="[
                            { key: 'name', header: 'Name' },
                        ]"
                        >
                    </app-autocomplete-dropdown>
                    <div class="form_validation_error" [ngClass]="{ form_error: (containerForm.get('lineAgentId')?.invalid && containerForm.get('lineAgentId')?.touched)}">
                        <small *ngIf="containerForm.get('lineAgentId')?.errors?.['required']">Line Agent is required</small>
                    </div>
                </div>
                <div class="form-item vesselItem" *ngIf="isFieldInGrid('vesselId')">
                    <label for="vesselId" class="form-label">Vessel Name<span *ngIf="isFieldRequired('vesselId')" class="required">*</span></label>
                    <app-autocomplete-dropdown
                        id="vesselId"
                        name="vesselId"
                        formControlName="vesselId"
                        class = "autocomplete-dropdown"
                        [placeholder]="'Select'"
                        [apiUrl]="'v1/vessel-port-train-details/list'"
                        [selectFields]="['id', 'vesselName','viaNo']"
                        [whereFields]="['vesselName']"
                        [limit]="10"
                        [orderBy]="{ vesselName: 'asc' }"
                        [filterByKey]="'vesselName'"
                        [bindLabel]="'vesselName'"
                        [bindValue]="'id'"
                        [disable]="containerForm.disabled"
                        [displayColumns]="[
                            { key: 'vesselName', header: 'Vessel Name' },
                            { key: 'viaNo', header: 'Via No' },
                        ]"
                        >
                    </app-autocomplete-dropdown>
                    <div class="form_validation_error" [ngClass]="{ form_error: (containerForm.get('vesselId')?.invalid && containerForm.get('vesselId')?.touched)}">
                        <small *ngIf="containerForm.get('vesselId')?.errors?.['required']">Vessel is required</small>
                    </div>
                </div>

                <div class="form-item isoCodeItem" *ngIf="isFieldInGrid('isoCode')">
                    <label for="isoCode" class="form-label">ISO Code<span *ngIf="isFieldRequired('isoCode')" class="required">*</span></label>
                    <!-- <input type="text" id="containerSize" formControlName="containerSize" class="form-input" required />-->
                    <app-autocomplete-dropdown
                        id="isoCode"
                        name="isoCode"
                        formControlName="isoCode"
                        class = "autocomplete-dropdown"
                        [placeholder]="'Select'"
                        [apiUrl]="'v1/size-type/alias/list'"
                        [selectFields]="['id', 'alias', 'sizeTypeId.size AS size', 'sizeTypeId.type AS type']"
                        [whereFields]="['alias']"
                        [limit]="10"
                        [orderBy]="{ alias: 'asc' }"
                        [filterByKey]="'alias'"
                        [bindLabel]="'alias'"
                        [bindValue]="'alias'"
                        [disable]="containerForm.disabled"
                        [displayColumns]="[
                            { key: 'alias', header: 'ISO' },
                            { key: 'size', header: 'Size' },
                            { key: 'type', header: 'Type' },
                        ]"
                        (suggestionSelected) = "onIsoCodeSelection($event)"
                        >
                    </app-autocomplete-dropdown>
                    <div class="form_validation_error" [ngClass]="{ form_error: (containerForm.get('isoCode')?.invalid && containerForm.get('isoCode')?.touched)}">
                        <small *ngIf="containerForm.get('isoCode')?.errors?.['required']">ISO Code is required</small>
                    </div>
                </div>

                <div class = "contSizeTypeGroup" *ngIf="isFieldInGrid('contSizeType')">
                    <div class="form-item contSizeTypeGroup">
                        <label for="containerSize" class="form-label">
                            Size<span *ngIf="isFieldRequired('containerSize')" class="required">*</span>
                        </label>
                        <input type="text" id="containerSize" formControlName="containerSize" class="form-input" readonly/>
                    </div>

                    <div class="form-item contSizeTypeGroup">
                        <label for="containerType" class="form-label">
                            Type<span *ngIf="isFieldRequired('containerType')" class="required">*</span>
                        </label>
                        <input type="text" id="containerType" formControlName="containerType" class="form-input" readonly/>
                    </div>
                </div>
                <div class="form-item lineSealItem" *ngIf="isFieldInGrid('lineSealNo')">
                    <label for="lineSealNo" class="form-label">
                        Line Seal<span *ngIf="isFieldRequired('lineSealNo')" class="required">*</span>
                    </label>
                    <input type="text" id="lineSealNo" formControlName="lineSealNo" class="form-input" placeholder="Enter line seal no"/>
                    <div class="form_validation_error" [ngClass]="{ form_error: (containerForm.get('lineSealNo')?.invalid && containerForm.get('lineSealNo')?.touched)}">
                        <small *ngIf="containerForm.get('lineSealNo')?.errors?.['required']">Line Seal No is required</small>
                    </div>
                </div>

                <div class="form-item physicalSealItem" *ngIf="isFieldInGrid('physicalSealNo')">
                    <label for="physicalSealNo" class="form-label">
                    Physical Seal<span *ngIf="isFieldRequired('physicalSealNo')" class="required">*</span>
                    </label>
                    <input type="text" id="physicalSealNo" formControlName="physicalSealNo" class="form-input" value="Vessel" placeholder="Enter pysical seal no"/>
                    <div class="form_validation_error" [ngClass]="{form_error: (containerForm.get('physicalSealNo')?.invalid && containerForm.get('physicalSealNo')?.touched)}">
                        <small *ngIf="containerForm.get('physicalSealNo')?.errors?.['required']">Physical Seal No is required</small>
                    </div>
                </div>

                <div class="form-item tareWeightItem" *ngIf="isFieldInGrid('tareWeight')">
                    <label for="tareWeight" class="form-label">
                        Tare Weight<span *ngIf="isFieldRequired('tareWeight')" class="required">*</span>
                    </label>
                    <input type="text" id="tareWeight" formControlName="tareWeight" class="form-input" placeholder="Enter tare weight"/>
                    <div class="form_validation_error" [ngClass]="{form_error: (containerForm.get('tareWeight')?.invalid && containerForm.get('tareWeight')?.touched)}">
                        <small *ngIf="containerForm.get('tareWeight')?.errors?.['required']">Tare Weight is required</small>
                    </div>
                </div>

                <div class="form-item grossWeightField" *ngIf="isFieldInGrid('grossWeight')">
                    <label for="grossWeight" class="form-label">
                    Gross Weight<span *ngIf="isFieldRequired('grossWeight')" class="required">*</span>
                    </label>
                    <input type="text" id="grossWeight" formControlName="grossWeight" class="form-input" value="Vessel" placeholder="Enter gross weight"/>
                    <div class="form_validation_error" [ngClass]="{form_error: (containerForm.get('grossWeight')?.invalid && containerForm.get('grossWeight')?.touched)}">
                        <small *ngIf="containerForm.get('grossWeight')?.errors?.['required']">Gross Weight is required</small>
                    </div>
                </div>

                <div class="form-item eirNoField" *ngIf="isFieldInGrid('eirNo')">
                    <label for="eirNo" class="form-label">EIR No<span *ngIf="isFieldRequired('eirNo')" class="required">*</span></label>
                    <input type="text" id="eirNo" formControlName="eirNo" class="form-input" />
                    <div class="form_validation_error" [ngClass]="{form_error: (containerForm.get('eirNo')?.invalid && containerForm.get('eirNo')?.touched)}">
                        <small *ngIf="containerForm.get('eirNo')?.errors?.['required']">EIR No is required</small>
                    </div>
                </div>

                <div class="form-item eirDateField" *ngIf="isFieldInGrid('eirDate')">
                    <label for="eirDate" class="form-label">
                        EIR Date<span *ngIf="isFieldRequired('eirDate')" class="required">*</span>
                    </label>
                    <input type="datetime-local" id="eirDate" formControlName="eirDate" class="form-input" />
                    <div class="form_validation_error" [ngClass]="{form_error: (containerForm.get('eirDate')?.invalid && containerForm.get('eirDate')?.touched)}">
                        <small *ngIf="containerForm.get('eirDate')?.errors?.['required']">EIR Date is required</small>
                    </div>
                </div>

                <div class="scanTypeGroup" *ngIf="isFieldInGrid('scanTypeGroup')">
                    <div class="form-item scanTypeGroup">
                        <label for="scanType" class="form-label">
                            Scan Type<span *ngIf="isFieldRequired('scanType')" class="required">*</span>
                        </label>
                        <select id="scanType" formControlName="scanType" class="form-select" >
                            <option value="" disabled>Select</option>
                            <option *ngFor="let option of scanTypeOptions" [value]="option">{{ option }}</option>
                        </select>
                        <div class="form_validation_error" [ngClass]="{form_error: (containerForm.get('scanType')?.invalid && containerForm.get('scanType')?.touched)}">
                            <small *ngIf="containerForm.get('scanType')?.errors?.['required']">Scan Type is required</small>
                        </div>
                    </div>

                    <!--<div class="form-item scanTypeGroup">
                        <label for="scanTypeActual" class="form-label">
                            Scan Type Actual<span class="required">*</span>
                        </label>
                        <select id="scanTypeActual" formControlName="scanType" class="form-select" required>
                            <option value="" disabled>Select</option>
                            <option *ngFor="let option of laneOptions" [value]="option">{{ option }}</option>
                        </select>
                    </div>-->
                </div>


                <div class="form-item scanStatusField" *ngIf="isFieldInGrid('scanStatus')">
                    <label for="scanStatus" class="form-label">Scan Status<span *ngIf="isFieldRequired('scanStatus')" class="required">*</span></label>
                    <select id="scanStatus" formControlName="scanStatus" class="form-select">
                        <option value="" disabled>Select</option>
                        <option *ngFor="let option of scanStatusOptions" [value]="option.key">{{ option.value }}</option>
                    </select>
                    <div class="form_validation_error" [ngClass]="{form_error: (containerForm.get('scanStatus')?.invalid && containerForm.get('scanStatus')?.touched)}">
                        <small *ngIf="containerForm.get('scanStatus')?.errors?.['required']">Scan Status is required</small>
                    </div>
                </div>

                <div class="form-item hazField" *ngIf="isFieldInGrid('hazardous')">
                    <app-toggle toggleId="hazardous"
                    label="Hazardous"
                    formControlName="hazardous"
                    (toggleChange)="onHazChange($event)"
                    ></app-toggle>
                </div>
                <div class="form-item odcField" *ngIf="isFieldInGrid('odc')">
                    <app-toggle toggleId="odc"
                    label="ODC"
                    formControlName="odc"
                    (toggleChange)="onOdcChange($event)"
                    ></app-toggle>
                </div>
                <div class="form-item reeferField" *ngIf="isFieldInGrid('reeferPlugin')">
                    <app-toggle toggleId="reeferPlugin"
                        label="Reefer Plugin"
                        formControlName="reeferPlugin"
                        (toggleChange)="onReeferPluginChange($event)"
                        >
                    </app-toggle>
                </div>

                <div class="tempGroup" *ngIf="isFieldInGrid('tempGroup')">
                    <div class="form-item tempGroup">
                        <label for="setTemperature" class="form-label">
                            Set Temperature<span *ngIf="isFieldRequired('setTemperature')" class="required">*</span>
                        </label>
                        <input type="text" id="setTemperature" formControlName="setTemperature" class="form-input" />
                        <div class="form_validation_error" [ngClass]="{form_error: (containerForm.get('setTemperature')?.invalid && containerForm.get('setTemperature')?.touched)}">
                            <small *ngIf="containerForm.get('setTemperature')?.errors?.['required']">Set Temperature is required</small>
                        </div>
                    </div>
                    <div class="form-item tempGroup">
                        <label for="temperature" class="form-label">
                            Temperature<span *ngIf="isFieldRequired('temperature')" class="required">*</span>
                        </label>
                        <input type="text" id="temperature" formControlName="temperature" class="form-input" />
                        <div class="form_validation_error" [ngClass]="{form_error: (containerForm.get('temperature')?.invalid && containerForm.get('temperature')?.touched)}">
                            <small *ngIf="containerForm.get('temperature')?.errors?.['required']">Temperature is required</small>
                        </div>
                    </div>
                </div>

                <div class="form-item fromLocationField" *ngIf="isFieldInGrid('fromLocationId')">
                    <label for="fromLocationId" class="form-label">
                        From Location<span *ngIf="isFieldRequired('fromLocationId')" class="required">*</span>
                    </label>
                    <app-autocomplete-dropdown
                        id="fromLocationId"
                        name="fromLocationId"
                        formControlName="fromLocationId"
                        class = "autocomplete-dropdown"
                        [placeholder]="'Select'"
                        [apiUrl]="'v1/locations/list'"
                        [selectFields]="['id', 'name', 'locationType']"
                        [whereFields]="['name']"
                        [limit]="10"
                        [orderBy]="{ name: 'asc' }"
                        [filterByKey]="'name'"
                        [bindLabel]="'name'"
                        [bindValue]="'id'"
                        [disable]="containerForm.disabled"
                        [displayColumns]="[
                            { key: 'name', header: 'Name' },
                            { key: 'locationType', header: 'Lcoation Type' },
                        ]"
                        >
                    </app-autocomplete-dropdown>
                    <div class="form_validation_error" [ngClass]="{form_error: (containerForm.get('fromLocationId')?.invalid && containerForm.get('fromLocationId')?.touched)}">
                        <small *ngIf="containerForm.get('fromLocationId')?.errors?.['required']">Please select the from location</small>
                    </div>
                </div>

                <div class="form-item conditionField" *ngIf="isFieldInGrid('condition')">
                    <label for="condition" class="form-label">
                        Condition<span *ngIf="isFieldRequired('condition')" class="required">*</span>
                    </label>
                    <select id="condition" formControlName="condition" class="form-select" >
                        <option value="" disabled>Select</option>
                        <option *ngFor="let option of conditionOptions" [value]="option.key">{{ option.value }}</option>
                    </select>
                    <div class="form_validation_error" [ngClass]="{form_error: (containerForm.get('condition')?.invalid && containerForm.get('condition')?.touched)}">
                        <small *ngIf="containerForm.get('condition')?.errors?.['required']">Please select the condition</small>
                    </div>
                </div>

                <div class="form-item remarksField" *ngIf="isFieldInGrid('remarks')">
                    <label for="remarks" class="form-label">Remarks</label>
                    <textarea class="form-text-area" id="remarks" name="remarks" placeholder="Enter remarks"></textarea>
                </div>
            </div>
            </form>
        </div>
    </div>
</div>
