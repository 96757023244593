<div class="header">
  <div class="page_header">
    <div class="left">
      <div class="title">Item Filing</div>
      <div class="sub_heading">Fill in Item Details and add Container Information</div>
    </div>
    <div class="right">
      @if(readOnlyMode){
      <button class="secondary_button" (click)="goBack()">Go Back</button>
      }
      @if(!readOnlyMode){
        <button class="secondary_button" (click)="goBack()">Cancel</button>
        <button class="primary_button" (click)="submitForm()">@if(editingEnabled){Update} @else {Save}</button>
      }
    </div>
  </div>
  <div class="horizontal_tabs">
    <div class="tabs">
      <div class="tab" (click)="scrollToSection('allSection')">
        <div class="tab_name" [class.tab_name_active]="selectedTab === 'allSection'">All</div>
        <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'allSection'"></div>
      </div>

      <div class="tab" (click)="scrollToSection('itemDetailsSection')">
        <div class="tab_name" [class.tab_name_active]="selectedTab === 'itemDetailsSection'">Item Details</div>
        <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'itemDetailsSection'"></div>
      </div>

      <div class="tab" (click)="scrollToSection('containerDetailsSection')">
        <div class="tab_name" [class.tab_name_active]="selectedTab === 'containerDetailsSection'">Container Details
        </div>
        <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'containerDetailsSection'"></div>
      </div>
    </div>
    <div class="divider"></div>
  </div>
</div>

<div class="full_page_container">
  <form [formGroup]="itemFilingForm">
    <section #allSection class="igm_details_form">
      <div class="section_title">IGM Details</div>
      <div class="divider"></div>
      <div class="form-row">
        <div class="form-group" #formField>
          <label for="igmNo" class="label required_field_label">IGM No </label>
          <input
            id="igmNo"
            type="text"
            formControlName="igmNo"
            placeholder="Enter IGM No"
            [ngClass]="{
                  form_error:
                    itemFilingForm.get('igmNo')?.invalid &&
                    itemFilingForm.get('igmNo')?.touched &&
                    itemFilingForm.get('igmNo')?.errors
                }"
          />
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('igmNo')?.invalid && itemFilingForm.get('igmNo')?.touched}">
            <small *ngIf="itemFilingForm.get('igmNo')?.errors?.['required']">IGM No. is required</small>
          </div>
        </div>

        <div class="form-group" #formField>
          <label for="itemNo" class="label required_field_label">Item No </label>
          <input id="itemNo" type="number" formControlName="itemNo" placeholder="Enter Item Number"
                 [ngClass]="{
                  form_error:
                    itemFilingForm.get('itemNo')?.invalid &&
                    itemFilingForm.get('itemNo')?.touched &&
                    itemFilingForm.get('itemNo')?.errors
                }"
          />
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('itemNo')?.invalid && itemFilingForm.get('itemNo')?.touched}">
            <small *ngIf="itemFilingForm.get('itemNo')?.errors?.['required']">Item No. is required</small>
          </div>
        </div>

        <div class="form-group" #formField>
          <label for="subItemNo" class="label required_field_label">Sub Item No</label>
          <input id="subItemNo" type="number" formControlName="subItemNo" placeholder="Enter Sub Item No"
                 [ngClass]="{
                  form_error:
                    itemFilingForm.get('subItemNo')?.invalid &&
                    itemFilingForm.get('subItemNo')?.touched &&
                    itemFilingForm.get('subItemNo')?.errors
                }"
          />
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('subItemNo')?.invalid && itemFilingForm.get('subItemNo')?.touched}">
            <small *ngIf="itemFilingForm.get('subItemNo')?.errors?.['required']">Sub Item No. is required</small>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group" #formField>
          <label for="igmDate" class="label">IGM Date</label>
          <input id="igmDate" type="date" formControlName="igmDate" placeholder="Enter IGM Date" />
        </div>

        <div class="form-group" #formField>
          <label for="blDate" class="label">Bill of Lading Date </label>
          <input id="blDate" type="date" formControlName="blDate" placeholder="Enter Bill of Lading Date" />
        </div>

        <div class="form-group" #formField>
          <label for="blNo" class="label">BL No </label>
          <input id="blNo" type="text" formControlName="blNo" placeholder="Enter BL Number" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group" #formField>
          <label for="vesselId" class="label">Vessel No </label>
          <app-autocomplete-dropdown
            id="vesselId"
            formControlName="vesselId"
            [placeholder]="'Enter Vessel Number'"
            [apiUrl]="'v1/vessel-port-train-details/list'"
            [selectFields]="['id','vesselName','viaNo']"
            [limit]="10"
            [whereFields]="['vesselName', 'viaNo']"
            [orderBy]="{ vesselName: 'asc' }"
            [filterByKey]="'vesselName'"
            [bindLabel]="'vesselName'"
            [bindValue]="'id'"
            [displayColumns]="[
                        { key: 'vesselName', header: 'Vessel Name' },
                        { key: 'viaNo', header: 'VIA Number' },
                    ]"
            (suggestionSelected)="autoPopulateViaNumber($event)"
            [disable]="readOnlyMode"
          ></app-autocomplete-dropdown>
        </div>

        <div class="form-group" #formField>
          <label for="viaNo" class="label">VIA No </label>
          <input type="text" id="viaNo" formControlName="viaNo" placeholder="Enter Via Number">
        </div>

        <div class="form-group" #formField>
          <label for="portOfLoading" class="label">POL </label>
          <app-autocomplete-dropdown
            id="portOfLoading"
            formControlName="portOfLoading"
            [placeholder]="'Enter POL'"
            [apiUrl]="'v1/locations/list'"
            [selectFields]="['id','name','panNumber']"
            [limit]="10"
            [whereFields]="['name','panNumber']"
            [orderBy]="{ name: 'asc' }"
            [filterByKey]="'name'"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [displayColumns]="[
                        { key: 'name', header: 'Location Name' },
                        { key: 'panNumber', header: 'PAN Number' },
                    ]"
            [disable]="readOnlyMode"
          ></app-autocomplete-dropdown>
        </div>
      </div>
    </section>
    <section class="account_holder_details_form">
      <div class="section_title">Account Holder Details</div>
      <div class="divider"></div>
      <div class="form-row">
        <div class="form-group" #formField>
          <label for="accountHolderId" class="label required_field_label">Account Holder</label>
          <app-autocomplete-dropdown
            id="accountHolderId"
            formControlName="accountHolderId"
            [placeholder]="'Enter Account Holder'"
            [apiUrl]="'v1/customers/list'"
            [selectFields]="['id', 'name', 'code']"
            [orderBy]="{ name: 'asc' }"
            [filterByKey]="'name'"
            [whereFields]="['name', 'code']"
            [limit]="10"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [displayColumns]="[
      { key: 'name', header: 'Name' },
      { key: 'code', header: 'Code' }
    ]"
            [ngClass]="{
      form_error:
        itemFilingForm.get('accountHolderId')?.invalid &&
        itemFilingForm.get('accountHolderId')?.touched &&
        itemFilingForm.get('accountHolderId')?.errors
    }"
            [disable]="readOnlyMode"
          ></app-autocomplete-dropdown>
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('accountHolderId')?.invalid && itemFilingForm.get('accountHolderId')?.touched}">
            <small *ngIf="itemFilingForm.get('accountHolderId')?.errors?.['required']">Account Holder is required</small>
          </div>
        </div>

        <div class="form-group" #formField>
          <label for="chaId" class="label required_field_label">CHA</label>
          <app-autocomplete-dropdown
            id="chaId"
            formControlName="chaId"
            [placeholder]="'Enter CHA'"
            [apiUrl]="'v1/customers/list'"
            [selectFields]="['id', 'name', 'code']"
            [orderBy]="{ name: 'asc' }"
            [whereFields]="['name', 'code']"
            [limit]="10"
            [filterByKey]="'name'"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [displayColumns]="[
      { key: 'name', header: 'Name' },
      { key: 'code', header: 'Code' }
    ]"
            [ngClass]="{
      form_error:
        itemFilingForm.get('chaId')?.invalid &&
        itemFilingForm.get('chaId')?.touched &&
        itemFilingForm.get('chaId')?.errors
    }"
            [disable]="readOnlyMode"
          ></app-autocomplete-dropdown>
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('chaId')?.invalid && itemFilingForm.get('chaId')?.touched}">
            <small *ngIf="itemFilingForm.get('chaId')?.errors?.['required']">CHA is required</small>
          </div>
        </div>

        <div class="form-group" #formField>
          <label for="importerFromIgm" class="label">Importer from IGM</label>
          <input
            id="importerFromIgm"
            formControlName="importerFromIgm"
            type="text"
            placeholder="Enter Importer From IGM"
          >
        </div>

        <div class="form-group" #formField>
          <label for="importerAddressFromIgm" class="label">Importer address from IGM </label>
          <input
            id="importerAddressFromIgm"
            formControlName="importerAddressFromIgm"
            type="text"
            placeholder="Enter Importer address from IGM"
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-group" #formField>
          <label for="consigneeId" class="label required_field_label">Consignee </label>
          <app-autocomplete-dropdown
            id="consigneeId"
            formControlName="consigneeId"
            [placeholder]="'Enter Consignee'"
            [apiUrl]="'v1/customers/list'"
            [selectFields]="['id', 'name', 'code','contactInformation']"
            [orderBy]="{ name: 'asc' }"
            [whereFields]="['name', 'code']"
            [limit]="10"
            [filterByKey]="'name'"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [displayColumns]="[
      { key: 'name', header: 'Name' },
      { key: 'code', header: 'Code' }
    ]"
            [ngClass]="{
      form_error:
        itemFilingForm.get('consigneeId')?.invalid &&
        itemFilingForm.get('consigneeId')?.touched &&
        itemFilingForm.get('consigneeId')?.errors
    }"
            [disable]="readOnlyMode"
            (suggestionSelected)="autoPopulateConsigneeAddress($event)"
          ></app-autocomplete-dropdown>
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('consigneeId')?.invalid && itemFilingForm.get('consigneeId')?.touched}">
            <small *ngIf="itemFilingForm.get('consigneeId')?.errors?.['required']">Consignee is required</small>
          </div>
        </div>

        <div class="form-group" #formField>
          <label for="consigneeAddress" class="label">Consignee Address</label>
          <input id="consigneeAddress" type="text" formControlName="consigneeAddress"
                 placeholder="Enter Consignee Address" />
        </div>

        <div class="form-group" #formField>
          <label for="notifyPartyId" class="label required_field_label">Notify Party </label>
          <app-autocomplete-dropdown
            id="notifyPartyId"
            formControlName="notifyPartyId"
            [placeholder]="'Enter Notify Party'"
            [apiUrl]="'v1/customers/list'"
            [selectFields]="['id', 'name', 'code','contactInformation']"
            [orderBy]="{ name: 'asc' }"
            [whereFields]="['name', 'code']"
            [limit]="10"
            [filterByKey]="'name'"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [displayColumns]="[
                        { key: 'name', header: 'Name' },
                        { key: 'code', header: 'Code' },
                    ]"
            (suggestionSelected)="autoPopulateNotifyParty($event)"
            [ngClass]="{
      form_error:
        itemFilingForm.get('notifyPartyId')?.invalid &&
        itemFilingForm.get('notifyPartyId')?.touched &&
        itemFilingForm.get('notifyPartyId')?.errors
    }"
            [disable]="readOnlyMode"
          ></app-autocomplete-dropdown>
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('notifyPartyId')?.invalid && itemFilingForm.get('notifyPartyId')?.touched}">
            <small *ngIf="itemFilingForm.get('notifyPartyId')?.errors?.['required']">Notify Party is required</small>
          </div>
        </div>

        <div class="form-group" #formField>
          <label for="notifyPartyAddress" class="label">Notify Party Address</label>
          <input id="notifyPartyAddress" type="text" formControlName="notifyPartyAddress"
                 placeholder="Enter Notify Party Address" />
        </div>
      </div>
<!--      TODO: Add this post discussion-->
<!--      <div class="form-row">-->
<!--        <div class="form-group" #formField>-->
<!--          <label for="shippingLine" class="label required_field_label">Shipping Line</label>-->
<!--          <app-autocomplete-dropdown-->
<!--            id="shippingLine"-->
<!--            formControlName="shippingLine"-->
<!--            [placeholder]="'Enter Shipping Line'"-->
<!--            [apiUrl]="'v1/customers/list'"-->
<!--            [selectFields]="['id', 'name', 'code']"-->
<!--            [orderBy]="{ name: 'asc' }"-->
<!--            [whereFields]="['name', 'code']"-->
<!--            [limit]="10"-->
<!--            [filterByKey]="'name'"-->
<!--            [bindLabel]="'name'"-->
<!--            [bindValue]="'id'"-->
<!--            [displayColumns]="[-->
<!--                        { key: 'name', header: 'Name' },-->
<!--                        { key: 'code', header: 'Code' },-->
<!--                    ]"-->
<!--            [ngClass]="{-->
<!--      form_error:-->
<!--        itemFilingForm.get('shippingLine')?.invalid &&-->
<!--        itemFilingForm.get('shippingLine')?.touched &&-->
<!--        itemFilingForm.get('shippingLine')?.errors-->
<!--    }"-->
<!--            [disable]="readOnlyMode"-->
<!--          ></app-autocomplete-dropdown>-->
<!--          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('shippingLine')?.invalid && itemFilingForm.get('shippingLine')?.touched}">-->
<!--            <small *ngIf="itemFilingForm.get('shippingLine')?.errors?.['required']">Shipping Line is required</small>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

    </section>
    <section #itemDetailsSection class="item_details_form">
      <div class="section_title">Item Details</div>
      <div class="divider"></div>
      <div class="form-row">
        <div class="form-group" #formField>
          <label for="boeNo" class="label required_field_label">BOE No</label>
          <input id="boeNo" type="text" formControlName="boeNo" placeholder="Enter BOE Number"
                 [ngClass]="{
      form_error:
        itemFilingForm.get('boeNo')?.invalid &&
        itemFilingForm.get('boeNo')?.touched &&
        itemFilingForm.get('boeNo')?.errors
    }"/>
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('boeNo')?.invalid && itemFilingForm.get('boeNo')?.touched}">
            <small *ngIf="itemFilingForm.get('boeNo')?.errors?.['required']">BOE No. is required</small>
          </div>
        </div>

        <div class="form-group" #formField>
          <label for="boeDate" class="label required_field_label">BOE Date</label>
          <input id="boeDate" type="date" formControlName="boeDate"
                 [ngClass]="{
      form_error:
        itemFilingForm.get('boeDate')?.invalid &&
        itemFilingForm.get('boeDate')?.touched &&
        itemFilingForm.get('boeDate')?.errors
    }"/>
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('boeDate')?.invalid && itemFilingForm.get('boeDate')?.touched}">
            <small *ngIf="itemFilingForm.get('boeNo')?.errors?.['required']">BOE Date is required</small>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group" #formField>
          <app-toggle
            id="smtpMovement"
            [label]="'SMTP'" [toggleId]="'smtpMovement'"
            [isActive]="itemFilingForm.get('smtpMovement')?.value"
            (toggleChange)="onSmtpToggle($event)"
            [disable]="readOnlyMode"
            formControlName="smtpMovement"
          ></app-toggle>
        </div>
        @if (itemFilingForm.get('smtpMovement')?.value) {
          <div class="form-group" #formField>
            <label for="smtpNo" class="label required_field_label">SMTP No</label>
            <input id="smtpNo" type="text" formControlName="smtpNo"
                   [ngClass]="{
      form_error:
        itemFilingForm.get('smtpNo')?.invalid &&
        itemFilingForm.get('smtpNo')?.touched &&
        itemFilingForm.get('smtpNo')?.errors
    }"
            />
            <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('smtpNo')?.invalid && itemFilingForm.get('smtpNo')?.touched}">
              <small *ngIf="itemFilingForm.get('smtpNo')?.errors?.['required']">SMTP Number is required</small>
            </div>
          </div>
        }
      </div>
      <div class="form-row">
        <div class="form-group" #formField>
          <label for="numberOfPackages" class="label required_field_label">Total NOP</label>
          <input
            id="numberOfPackages"
            formControlName="numberOfPackages"
            type="text"
            placeholder="Enter Total NOP"
            [ngClass]="{
      form_error:
        itemFilingForm.get('numberOfPackages')?.invalid &&
        itemFilingForm.get('numberOfPackages')?.touched &&
        itemFilingForm.get('numberOfPackages')?.errors
    }"
          >
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('numberOfPackages')?.invalid && itemFilingForm.get('numberOfPackages')?.touched}">
            <small *ngIf="itemFilingForm.get('numberOfPackages')?.errors?.['required']">Number of packages is required</small>
          </div>
        </div>

        <div class="form-group" #formField>
          <label for="packageTypeId" class="label">Package Type</label>
          <app-autocomplete-dropdown
            id="packageTypeId"
            formControlName="packageTypeId"
            [placeholder]="'Enter Package Type'"
            [apiUrl]="'v1/packages/list'"
            [selectFields]="['id', 'name', 'code']"
            [orderBy]="{ name: 'asc' }"
            [whereFields]="['name', 'code']"
            [limit]="10"
            [filterByKey]="'name'"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [displayColumns]="[
                        { key: 'name', header: 'Name' },
                        { key: 'code', header: 'Code' },
                    ]"
            [disable]="readOnlyMode"
          ></app-autocomplete-dropdown>
        </div>

        <div class="form-group" #formField>
          <label for="grossWeight" class="label required_field_label">Gross Weight (In MT)</label>
          <input id="grossWeight" type="number" formControlName="grossWeight"
                 placeholder="Enter Gross Weight (In MT)"
                 [ngClass]="{
      form_error:
        itemFilingForm.get('grossWeight')?.invalid &&
        itemFilingForm.get('grossWeight')?.touched &&
        itemFilingForm.get('grossWeight')?.errors
    }"
          />
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('grossWeight')?.invalid && itemFilingForm.get('grossWeight')?.touched}">
            <small *ngIf="itemFilingForm.get('grossWeight')?.errors?.['required']">Number of packages is required</small>
          </div>
        </div>

        <div class="form-group" #formField>
          <label for="grossVolume" class="label">Volume (In CBM)</label>
          <input id="grossVolume" type="number" formControlName="grossVolume" placeholder="Enter Volume (In CBM)" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group" #formField>
          <app-toggle id="dpd" [label]="'DPD'" [toggleId]="'dpd'" [isActive]="itemFilingForm.get('dpd')?.value"
                      (toggleChange)="onDpdToggle($event)" [disable]="readOnlyMode"></app-toggle>
        </div>
        <div class="form-group" #formField>
          <app-toggle [label]="'Hazardous'" [toggleId]="'hazardous'" [isActive]="itemFilingForm.get('hazardous')?.value" formControlName="hazardous"
                      (toggleChange)="onHazardousChange($event)" [disable]="readOnlyMode"></app-toggle>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group" #formField>
          <label for="assessableValue" class="label required_field_label">Assessable Value</label>
          <input id="assessableValue" type="number" formControlName="assessableValue"
                 placeholder="Enter Assessable Value"
                 [ngClass]="{
      form_error:
        itemFilingForm.get('assessableValue')?.invalid &&
        itemFilingForm.get('assessableValue')?.touched &&
        itemFilingForm.get('assessableValue')?.errors
    }"
          />
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('assessableValue')?.invalid && itemFilingForm.get('assessableValue')?.touched}">
            <small *ngIf="itemFilingForm.get('assessableValue')?.errors?.['required']">Assessable Value is required</small>
          </div>
        </div>

        <div class="form-group" #formField>
          <label for="dutyValue" class="label required_field_label">Duty Value</label>
          <input id="dutyValue" type="number" formControlName="dutyValue" placeholder="Enter Duty Value"
                 [ngClass]="{
      form_error:
        itemFilingForm.get('dutyValue')?.invalid &&
        itemFilingForm.get('dutyValue')?.touched &&
        itemFilingForm.get('dutyValue')?.errors
    }"
          />
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('dutyValue')?.invalid && itemFilingForm.get('dutyValue')?.touched}">
            <small *ngIf="itemFilingForm.get('dutyValue')?.errors?.['required']">Duty Value is required</small>
          </div>
        </div>
      </div>
      <div class="form-row">

        <div class="form-group" #formField>
          <label for="goodsDescription" class="label">Cargo Description</label>
          <input id="goodsDescription" type="text" formControlName="goodsDescription"
                 placeholder="Enter Cargo Description" />
        </div>

        <div class="form-group" #formField>
          <label for="commodityId" class="label required_field_label">Commodity</label>
          <app-autocomplete-dropdown
            id="commodityId"
            formControlName="commodityId"
            [placeholder]="'Enter Commodity Name'"
            [apiUrl]="'v1/commodities/list'"
            [selectFields]="['id', 'name', 'code','commodityCategoryId']"
            [orderBy]="{ name: 'asc' }"
            [whereFields]="['name', 'code','commodityCategoryId']"
            [limit]="10"
            [filterByKey]="'name'"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [displayColumns]="[
                        { key: 'name', header: 'Name' },
                        { key: 'code', header: 'Code' },
                        { key: 'commodityCategoryId', header: 'Commodity Category' },
                    ]"
            [disable]="readOnlyMode"
            [ngClass]="{
      form_error:
        itemFilingForm.get('commodityId')?.invalid &&
        itemFilingForm.get('commodityId')?.touched &&
        itemFilingForm.get('commodityId')?.errors
    }"
          ></app-autocomplete-dropdown>
          <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('commodityId')?.invalid && itemFilingForm.get('commodityId')?.touched}">
            <small *ngIf="itemFilingForm.get('commodityId')?.errors?.['required']">Commodity is required</small>
          </div>
        </div>

        <div class="form-group" #formField>
          <label for="examinationType" class="label">Examination type</label>
          <select id="examinationType" formControlName="examinationType">
            <option value="RMS">RMS</option>
            <option value="RMS_AND_EXAMINATION">RMS and Examination</option>
            <option value="SEAL_CUT">Seal Cut</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group" #formField>
          <app-toggle [label]="'High Seal'" [toggleId]="'isHighSeaSaleCustomer'" [isActive]="itemFilingForm.get('isHighSeaSaleCustomer')?.value"
                      (toggleChange)="onHighSealToggle($event)" [disable]="readOnlyMode"></app-toggle>
        </div>

        @if (itemFilingForm.get('isHighSeaSaleCustomer')?.value) {
          <div class="form-group" #formField>
            <label for="highSeaSaleCustomerId" class="label required_field_label">High Seal Customer Name</label>
            <app-autocomplete-dropdown
              id="highSeaSaleCustomerId"
              formControlName="highSeaSaleCustomerId"
              [placeholder]="'Enter High Seal Customer Name'"
              [apiUrl]="'v1/customers/list'"
              [selectFields]="['id', 'name', 'code','contactInformation']"
              [orderBy]="{ name: 'asc' }"
              [whereFields]="['name', 'code']"
              [limit]="10"
              [filterByKey]="'name'"
              [bindLabel]="'name'"
              [bindValue]="'id'"
              [displayColumns]="[
                        { key: 'name', header: 'Name' },
                        { key: 'code', header: 'Code' },
                    ]"
              (suggestionSelected)="autoPopulateHighSealCustomerAddress($event)"
              [disable]="readOnlyMode"
            ></app-autocomplete-dropdown>
            <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('highSeaSaleCustomerId')?.invalid && itemFilingForm.get('highSeaSaleCustomerId')?.touched}">
              <small *ngIf="itemFilingForm.get('highSeaSaleCustomerId')?.errors?.['required']">High Seal Customer Name is required</small>
            </div>
          </div>

          <div class="form-group" #formField>
            <label for="highSeaSaleCustomerNameAddress" class="label required_field_label">High Seal Customer Address</label>
            <input id="highSeaSaleCustomerNameAddress" type="text" formControlName="highSeaSaleCustomerNameAddress"
                   placeholder="Enter Address"
                   [ngClass]="{
      form_error:
        itemFilingForm.get('highSeaSaleCustomerNameAddress')?.invalid &&
        itemFilingForm.get('highSeaSaleCustomerNameAddress')?.touched &&
        itemFilingForm.get('highSeaSaleCustomerNameAddress')?.errors
    }"
            />
            <div class="form_validation_error_new" [ngClass]="{'visible': itemFilingForm.get('highSeaSaleCustomerNameAddress')?.invalid && itemFilingForm.get('highSeaSaleCustomerNameAddress')?.touched}">
              <small *ngIf="itemFilingForm.get('highSeaSaleCustomerNameAddress')?.errors?.['required']">High Seal Customer Address is required</small>
            </div>
          </div>
        }
      </div>
    </section>
  </form>
  <app-editable-table
    #containerDetailsSection
    [title]="'Associated Containers'"
    [subtitle]="'View, Edit and file the container Information'"
    [addButtonText]="'Add Container'"
    [tableHeaders]="tableHeaders"
    [tableData]="tableData"
    (addNewRecord)="addNewRecord($event)"
    (dataDeleted)="deleteRecord($event)"
    [searchBarConfig]="{
      displayColumns: [
        { key: 'containerNo', header: 'Container Number' },
        {key: 'isGatedIn',header: 'Gated In'}
        ],
        searchPlaceholder: 'Search By Container Number',
        apiMethod: getUnifiedSearchContainerApiMethod(),
        selectFields: ['id','containerNo'],
    }"
    [uniqueId]="'id'"
    [disable]="readOnlyMode"
    [noDataFoundErrorMsg]="'No Associated Containers Found'"
  ></app-editable-table>
</div>
