<div class="modal-backdrop"></div>
<div class="panel">
    <div class="header">
      <div class="title">{{title}}</div>
      <app-svg-icon
        class="close_btn"
        (click)="onClose()"
        [path]="'assets/icons/close_icon.svg'"
      ></app-svg-icon>
    </div>
    <div class="divider"></div>

  <div class="body">
    <ng-template #rightPanelContent></ng-template>
  </div>
</div>
