import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { FileUploadComponent } from "../../../file-upload/file-upload.component";
import { CheckboxComponent } from "../../../common/checkbox/checkbox.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { AutocompleteDropdownComponent } from "../../../autocomplete-dropdown/autocomplete-dropdown.component";
import { NgClass, NgIf, NgStyle } from "@angular/common";
import { LoadingService } from "../../../../services/loading.service";
import { ApiService } from "../../../../services/api.service";
import { UploadType } from "../../../../constants/gate-ops-constants";
import { ToasterService } from "../../../../services/toaster.service";
import { SvgIconComponent } from "../../../svg-icon/svg-icon.component";
import { IgmDeskFileUploadRes } from "../../../../models/igm-desk-file-upload-res";

@Component({
  selector: "app-file-upload-dialog",
  standalone: true,
  imports: [
    FileUploadComponent,
    CheckboxComponent,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteDropdownComponent,
    NgStyle,
    NgIf,
    NgClass,
    SvgIconComponent
  ],
  templateUrl: "./file-upload-dialog.component.html",
  styleUrl: "./file-upload-dialog.component.scss"
})
export class FileUploadDialogComponent {
  uploadForm: FormGroup | undefined;
  selectedFile!: File;
  uploadType: string = UploadType.IGM.key;
  allowedFileTypes: string[] = ["application/octet-stream", "text/plain", "IGM", "CGM", "TXT", "text/csv"];
  showUploadForm: boolean = true;


  protected readonly UploadType = UploadType;
  @ViewChild(FileUploadComponent) fileUploadComponent!: FileUploadComponent;
  @Output() refreshTableData = new EventEmitter<void>();
  igmDeskFileUploadRes!: IgmDeskFileUploadRes;

  constructor(private fb: FormBuilder,
              private loadingService: LoadingService,
              private api: ApiService,
              private toasterService: ToasterService) {
  }

  ngOnInit(): void {
    this.uploadForm = this.createIgmCsvForm();
  }

  closeRightPanel!: (params?: any) => void;


  close(params?: any) {
    if (this.closeRightPanel) {
      this.closeRightPanel(params);
    }
  }

  createFormForScanList(): FormGroup {
    return this.fb.group({});
  }

  createIgmCsvForm(): FormGroup {
    return this.fb.group({
      portOfDischargeId: ["", Validators.required],
      viaNumber: ["", Validators.required],
      primaryCustomer: ["", Validators.required],
      secondaryCustomer: [""],
      jobProvidedBy: ["", Validators.required]
    });
  }

  onSubmit(): void {
    this.uploadForm?.markAllAsTouched();
    if (!this.selectedFile) {
      this.fileUploadComponent.setError(true, "Please select a file to upload");
      return;
    }
    if (this.uploadForm?.valid) {
      const formValues = this.uploadForm.value;
      console.log(formValues);
      this.uploadIgmForm(formValues);
    } else {
      console.log("invalid form");
      // this.scrollToFirstInvalidControl();
    }
  }

  uploadIgmForm(formData: any): void {
    this.loadingService.show();
    console.log(formData);
    const formDataToSend = this.getFormDataToSend(formData);

    if (this.uploadType === UploadType.SCAN_LIST.key) {
      this.callApiForScanList(formDataToSend);
    } else {
      this.callApiForIgmCgmUpload(formDataToSend);
    }

  }

  private callApiForIgmCgmUpload(formDataToSend: FormData) {
    this.api.uploadIgmData(formDataToSend).subscribe({
      next: (res) => {
        this.igmDeskFileUploadRes = res?.data;
        this.handleFileUploadResponseData(res?.data);
        this.refreshTableData.emit();
        this.loadingService.hide();
        // this.showUploadForm = false

      },
      error: (err) => {
        console.log(err);
        this.toasterService.error(err.error.errorDesc);
        this.loadingService.hide();
      }
    });
  }

  private handleFileUploadResponseData(data: IgmDeskFileUploadRes) {
    switch (data.status) {
      case "SUCCESS":
        this.toasterService.success(`${data.successCount} records were added successfully`);
        this.close();
        break;
      case "PARTIAL_SUCCESS":
        this.showUploadForm = false;
        break;
      case "FAILED":
        this.showUploadForm = false;
        break;
      default:
        this.toasterService.error("Something went wrong");
    }
  }

  private callApiForScanList(formDataToSend: FormData) {
    this.api.uploadScanList(formDataToSend).subscribe({
      next: (res) => {
        console.log(res);
        this.toasterService.success(res.data);
        this.loadingService.hide();
        this.close();
      },
      error: (err) => {
        console.log(err);
        this.toasterService.error(err.error.errorDesc);
        this.loadingService.hide();
      }
    });
  }

  private getFormDataToSend(formData: any) {
    const formDataToSend = new FormData();
    formDataToSend.append("file", this.selectedFile);
    formDataToSend.append("source", this.uploadType);
    formDataToSend.append("viaNo", formData.viaNumber?.value);
    formDataToSend.append("primaryCustomerId", formData.primaryCustomer?.key);
    formDataToSend.append("secondaryCustomerId", formData.secondaryCustomer?.key);
    formDataToSend.append("jobProvidedBy", formData.jobProvidedBy?.key);
    formDataToSend.append("pod", formData.portOfDischargeId?.key);
    return formDataToSend;
  }

  getSelectedFile(file: File) {
    if (!file) {
      this.fileUploadComponent.setError(true, "Please select a file to upload");
      return;
    }
    this.selectedFile = file;
    console.log("Selected file:", file);
  }

  setUploadType(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.uploadType = inputElement.value;

    const currentValues = this.uploadForm?.value;
    console.log("Current values:", currentValues);
    if (this.uploadType === UploadType.SCAN_LIST.key) {
      this.uploadForm = this.fb.group({});
      // this.allowedFileTypes = ["text/csv"];
    } else if (this.uploadType === UploadType.CSV.key) {
      this.uploadForm = this.createIgmCsvForm();
      // this.allowedFileTypes = ["text/csv"];
    } else {
      this.uploadForm = this.createIgmCsvForm();
      // this.allowedFileTypes = ["application/octet-stream", "text/plain", "IGM", "CGM", "TXT"];
    }

    if (currentValues) {
      this.uploadForm.patchValue(currentValues);
    }
  }

  autoPopulateFields(event: any) {
    console.log(event);
    this.uploadForm?.patchValue({
      secondaryCustomer: { key: event?.id, value: event?.name },
      jobProvidedBy: { key: event?.id, value: event?.name }
    });
  }

  renderUploadForm() {
    this.showUploadForm = true;
    this.uploadForm?.reset();
  }

  downloadErrorFile() {
    const errorCsvBase64Encoded = this.igmDeskFileUploadRes?.errorCsv;

    if(!errorCsvBase64Encoded)
      return;

    const errorCsvDecoded = atob(errorCsvBase64Encoded);
    const file = new Blob([errorCsvDecoded], { type: "text/csv" });

    // Create a link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = "error.csv";
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  }
}
