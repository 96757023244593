import { Component, ElementRef, ViewChild, AfterViewInit, ViewChildren, QueryList } from "@angular/core";
import { EditableTableComponent } from "../../../editable-table/editable-table.component";
import { EditableTableHeader } from "../../../../models/editable-table-header.interface";
import tableHeaders from "../table-headers.json";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { AutocompleteDropdownComponent } from "../../../autocomplete-dropdown/autocomplete-dropdown.component";
import { ToggleComponent } from "../../../common/toggle/toggle.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../../services/api.service";
import { Observable } from "rxjs";
import { Container } from "../../../../models/container.interface";
import { DatePipe, NgClass, NgIf } from "@angular/common";
import { OperationService } from "../../../../services/operations.service";
import { CargoItem } from "../../../../models/cargo-item.interface";
import { LoadingService } from "../../../../services/loading.service";
import { SelectComponent } from "../../../select/select.component";
import { ToasterService } from "../../../../services/toaster.service";

@Component({
  selector: "app-manage-item-filing",
  standalone: true,
  imports: [
    EditableTableComponent,
    ReactiveFormsModule,
    AutocompleteDropdownComponent,
    ToggleComponent,
    NgIf,
    NgClass,
    SelectComponent
  ],
  templateUrl: "./manage-item-filing.component.html",
  styleUrls: ["./manage-item-filing.component.scss"]
})
export class ManageItemFilingComponent implements AfterViewInit {
  private sectionMap: Map<string, any> = new Map();

  tableHeaders: EditableTableHeader[] = [];
  tableData: Container[] = [];
  itemFilingForm!: FormGroup;
  itemFilingId: string = "";
  selectedTab: string = "allSection";
  editingEnabled: boolean = false;
  readOnlyMode: boolean = false;

  @ViewChild("allSection") allSection: ElementRef | undefined;
  @ViewChild("itemDetailsSection") itemDetailsSection: ElementRef | undefined;
  @ViewChild("containerDetailsSection", { read: ElementRef }) containerDetailsSection: ElementRef | undefined;
  @ViewChildren("formField") formFields!: QueryList<ElementRef>;
  deLinkedContainerIds: String[] = [];

  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private api: ApiService,
              private operationService: OperationService,
              private loadingService: LoadingService,
              private toasterService: ToasterService) {
    this.tableHeaders = tableHeaders;
    this.itemFilingForm = this.getItemFilingForm();
  }

  ngOnInit() {
    this.itemFilingId = this.route.snapshot.paramMap.get("item-filing-id") || "";

    if (this.itemFilingId === "") {
      console.log("Item Filing Id not found");
      this.readOnlyMode = false;
      this.editingEnabled = false;
      return;
    }

    if (this.route.snapshot.url[0].path === "edit") {
      this.editingEnabled = true;
      this.readOnlyMode = false;
    } else {
      this.editingEnabled = false;
      this.readOnlyMode = true;
      this.itemFilingForm.disable();
    }

    this.loadingService.show();
    this.operationService.getItemFilingById(this.itemFilingId).subscribe({
      next: (response: any) => {
        const data = response?.data;
        this.hydrateForm(data);

        this.tableData = this.resolveLookupFieldsInAssociatedContainersTable(data?.associatedContainerDtos);
        console.log("This table data => ", this.tableData);
        this.loadingService.hide();
      },
      error: (error) => {
        console.error(error);
        this.loadingService.hide();
      }
    });
  }

  private resolveLookupFieldsInAssociatedContainersTable(associatedContainers: Container[]) {
    return associatedContainers.map((item: any) => {
      const transformedItem = { ...item }; // Copy the item
      this.tableHeaders
        .filter(header => header?.hasLookup) // Filter headers with lookup configuration
        .forEach((header) => {
          const bindLabel = header.fieldInfo?.lookupDetails?.responseBindLabel;
          const bindValue = header.fieldInfo?.lookupDetails?.responseBindValue;
          const fieldKey = header?.label; // The field to update in the item
          if (bindLabel && bindValue && fieldKey) {
            transformedItem[fieldKey] = {
              key: item[bindValue] || null,
              value: item[bindLabel] || null
            };
          }
        });
      return transformedItem;
    });
  }

  private hydrateForm(data: any){
    if(data.smtpMovement){
      this.itemFilingForm.addControl("smtpNo", this.fb.control({
        value: data?.smtpNo,
        disabled: this.readOnlyMode
      }, Validators.required));
    }

    if(data?.isHighSeaSaleCustomer){
      this.itemFilingForm.addControl("highSeaSaleCustomerId", this.fb.control({
        value: data?.highSealCustomerId,
        disabled: this.readOnlyMode
      }, Validators.required));

      this.itemFilingForm.addControl("highSeaSaleCustomerNameAddress", this.fb.control({
        value: data?.highSeaSaleCustomerNameAddress,
        disabled: this.readOnlyMode
      }, Validators.required));
    }

    this.itemFilingForm.patchValue({
      ...data,
      boeDate: data?.boeDate?.split('T')[0],
      igmDate: data?.igmDate?.split('T')[0],
      blDate: data?.blDate?.split('T')[0],
      accountHolderId: {key: data?.accountHolderId, value: data?.accountHolderName},
      vesselId: {key: data?.vesselId, value: data?.vesselName},
      chaId: {key: data?.chaId, value: data?.chaName},
      consigneeId: {key: data?.consigneeId, value: data?.consigneeName},
      notifyPartyId: {key: data?.notifyPartyId, value: data?.notifyPartyName},
      packageTypeId: {key: data?.packageTypeId, value: data?.packageTypeValue},
      commodityId: {key: data?.commodityId, value: data?.commodityValue},
      highSeaSaleCustomerId: {key: data?.highSealCustomerId, value: data?.highSeaSaleCustomerName},
      portOfLoading: {key: data?.portOfLoading, value: data?.portOfLoadingName},
    });
  }

  ngAfterViewInit() {
    if (this.allSection) this.sectionMap.set("allSection", this.allSection.nativeElement);
    if (this.itemDetailsSection) this.sectionMap.set("itemDetailsSection", this.itemDetailsSection.nativeElement);
    if (this.containerDetailsSection) this.sectionMap.set("containerDetailsSection", this.containerDetailsSection.nativeElement);
  }

  scrollToSection(sectionId: string) {
    this.selectedTab = sectionId;
    const section = this.sectionMap.get(sectionId);
    const container = document.querySelector(".full_page_container");

    if (section && container) {
      const sectionTop = section.getBoundingClientRect().top - container.getBoundingClientRect().top;
      container.scrollTo({
        top: sectionTop + container.scrollTop,
        behavior: "smooth"
      });
    }
  }

  getUnifiedSearchContainerApiMethod() {
    return (value: string, selectFields: string) => this.api.searchUnifiedContainers(value, selectFields);
  }

  getItemFilingForm(): FormGroup {
    return this.fb.group({
      igmNo: ["", Validators.required],
      itemNo: ["", Validators.required],
      subItemNo: ["", Validators.required],
      igmDate: [null],
      blDate: [null],
      blNo: [""],
      vesselId: [""],
      viaNo: [""],
      portOfLoading: [""],
      accountHolderId: ["", Validators.required],
      chaId: ["", Validators.required],
      importerFromIgm: [""],
      importerAddressFromIgm: [""],
      consigneeId: ["", Validators.required],
      consigneeAddress: [""],
      notifyPartyId: ["", Validators.required],
      notifyPartyAddress: [""],
      // shippingLine: ["", Validators.required], //TODO: Handle this in the backend and send shipping line id from here
      boeNo: ["", Validators.required],
      boeDate: [null, Validators.required],
      smtpMovement: [false],
      numberOfPackages: ["",Validators.required],
      packageTypeId: [""],
      grossWeight: ["",Validators.required],
      grossVolume: [""],
      dpd: [false],
      hazardous: [false],
      assessableValue: ["",Validators.required],
      dutyValue: ["",Validators.required],
      goodsDescription: [""],
      commodityId: ["", Validators.required],
      examinationType: ["RMS"],
      isHighSeaSaleCustomer: [false]
    });
  }

  goBack() {
    this.router.navigateByUrl("/manage-operations/item-filing");
  }

  addNewRecord(record: any) {
    console.log(record);
    this.api.getUnifiedContainerById(record.id, !record?.isGatedIn).subscribe({
      next: (response: any) => {
        const data = this.resolveLookupFieldsInAssociatedContainersTable([response?.data])[0];
        this.tableData.push(data);
      },
      error: (error) => {
        this.toasterService.error(error?.error?.errorDesc)
        console.error(error);
      }
    });
  }

  autoPopulateViaNumber(event: any) {
    this.itemFilingForm.patchValue({
      viaNo: event?.viaNo
    });
  }

  autoPopulateConsigneeAddress(event: any) {
    const defaultContactInformation = event?.contactInformation?.find((contact: any) => contact.defaultContact);

    this.itemFilingForm.patchValue({
      consigneeAddress: defaultContactInformation?.address
    });
  }

  autoPopulateNotifyParty(event: any) {
    const defaultContactInformation = event?.contactInformation?.find((contact: any) => contact.defaultContact);

    this.itemFilingForm.patchValue({
      notifyPartyAddress: defaultContactInformation?.address
    });
  }

  onSmtpToggle(isActive: boolean) {
    this.itemFilingForm.get("smtpMovement")?.setValue(isActive);
    if (isActive) {
      this.itemFilingForm.addControl("smtpNo", this.fb.control({
        value: "",
        disabled: this.readOnlyMode
      }, Validators.required));
    } else {
      this.itemFilingForm.removeControl("smtpNo");
    }
  }

  onDpdToggle(active: boolean) {
    this.itemFilingForm.get("dpd")?.setValue(active);
  }

  onHazardousChange(active: boolean) {
    this.itemFilingForm.get("hazardous")?.setValue(active);
  }

  onHighSealToggle(active: boolean) {
    this.itemFilingForm.get("isHighSeaSaleCustomer")?.setValue(active);

    if (active) {
      this.itemFilingForm.addControl("highSeaSaleCustomerId", this.fb.control("", Validators.required));
      this.itemFilingForm.addControl("highSeaSaleCustomerNameAddress", this.fb.control({
        value: "",
        disabled: this.readOnlyMode
      }, Validators.required));
    } else {
      this.itemFilingForm.removeControl("highSeaSaleCustomerId");
      this.itemFilingForm.removeControl("highSeaSaleCustomerNameAddress");
    }
  }

  autoPopulateHighSealCustomerAddress(event: any) {
    const defaultContactInformation = event?.contactInformation?.find((contact: any) => contact.defaultContact);

    this.itemFilingForm.patchValue({
      highSeaSaleCustomerNameAddress: defaultContactInformation?.address
    });
  }

  submitForm() {
    console.log(this.tableData);

    this.itemFilingForm.markAllAsTouched();
    if (!this.itemFilingForm.valid) {
      this.scrollToFirstInvalidControl();
      console.log("Form is invalid");
      return
    }

    this.loadingService.show();
    const cargoItem = this.getCargoItem();

    if (this.editingEnabled) {
      //update method
      const cargoUpdateRequest = {
        cargoItem: { ...cargoItem },
        deLinkedContainerIds: this.deLinkedContainerIds,
      };
      this.updateItem(cargoUpdateRequest);
    } else {
      this.addItem(cargoItem);
    }

  }


  scrollToFirstInvalidControl() {
    const firstInvalidControl = this.formFields.find((element) => {
      return !element.nativeElement.validity?.valid;
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
      // firstInvalidControl.nativeElement.focus(); // Optionally, focus the invalid element
    }
  }

  deleteRecord(selectedRow: any) {
    selectedRow.forEach((row: any) => {
      const container: Container | undefined = this.tableData.find((item) => item.containerNo === row.containerNo);
      if (container) {
        this.deLinkedContainerIds.push(container.id);
      }
      this.tableData = this.tableData.filter((item) => item.containerNo !== row.containerNo);
    });
  }

  private getCargoItem(): CargoItem {
    this.tableData.forEach((item: any) => {
      this.tableHeaders
        .filter(header => header?.hasLookup)
        .forEach((header) => {
          const bindValue = header.fieldInfo?.lookupDetails?.responseBindValue;
          const fieldKey = header?.label;
          if (bindValue && fieldKey && item[fieldKey]) {
            const fieldData = item[fieldKey];
            if (typeof fieldData === 'object') {
              item[bindValue] = item[fieldKey].key;
            } else {
              item[bindValue] = fieldData;
            }
          }
        });
    });


    const cargoItem: CargoItem = {
      ...this.itemFilingForm?.value,
      portOfLoading: this.itemFilingForm?.get("portOfLoading")?.value?.key,
      accountHolderId: this.itemFilingForm?.get("accountHolderId")?.value?.key,
      vesselId: this.itemFilingForm?.get("vesselId")?.value?.key,
      chaId: this.itemFilingForm?.get("chaId")?.value?.key,
      consigneeId: this.itemFilingForm?.get("consigneeId")?.value?.key,
      notifyPartyId: this.itemFilingForm?.get("notifyPartyId")?.value?.key,
      packageTypeId: this.itemFilingForm?.get("packageTypeId")?.value?.key,
      commodityId: this.itemFilingForm?.get("commodityId")?.value?.key,
      highSeaSaleCustomerId: this.itemFilingForm?.get("highSeaSaleCustomerId")?.value?.key,
      associatedContainerDtos: this.tableData
    };
    console.log(cargoItem);
    return cargoItem;
  }

  private addItem(cargoItem: CargoItem) {
    this.operationService.submitItemFilingForm(cargoItem).subscribe({
      next: (response: any) => {
        this.loadingService.hide();
        this.toasterService.success(
          `Item has been added successfully!`
        );
        this.loadingService.hide();
        this.goBack();
      },
      error: (error) => {
        this.toasterService.error(error.error.errorDesc);
        console.error(error);
        this.loadingService.hide();
      }
    });
  }

  private updateItem(cargoItem: any) {
    this.operationService.updateItemFilingForm(this.itemFilingId, cargoItem).subscribe({
      next: (response: any) => {
        this.loadingService.hide();
        this.toasterService.success(
          `Item has been updated successfully!`
        );
        this.loadingService.hide();
        this.goBack();
      },
      error: (error) => {
        this.toasterService.error(error.error.errorDesc);
        console.error(error);
        this.loadingService.hide();
      }
    });
  }
}
