import { Component, Input } from "@angular/core";
import { DatePipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HighlightPipe } from "../../pipes/highlight.pipe";
import { NoDataComponent } from "../no-data/no-data.component";
import { NoDataFoundComponent } from "../no-data-found/no-data-found.component";
import { ResizableModule } from "angular-resizable-element";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";
import { TableMenuComponent } from "../table-menu/table-menu.component";
import { PaginatedListDataResponse } from "../../models/paginated-list-data-response";
import { ApiService } from "../../services/api.service";
import { LoadingService } from "../../services/loading.service";
import { ToasterService } from "../../services/toaster.service";

@Component({
  selector: 'app-login-history',
  standalone: true,
  imports: [
    DatePipe,
    FormsModule,
    HighlightPipe,
    NgForOf,
    NgIf,
    NoDataComponent,
    NoDataFoundComponent,
    ResizableModule,
    SvgIconComponent,
    TableMenuComponent,
    NgClass
  ],
  templateUrl: './login-history.component.html',
  styleUrl: './login-history.component.scss'
})
export class LoginHistoryComponent {
  @Input() userId!: string;
  tableListData: PaginatedListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: []
  };
  currentTablePage = 1;
  currentPage: number = 0;
  rowsPerPage: number = 10;
  rowsPerPageOptions: number[] = [];
  limit: number = 25;
  offset: number = 0;


  constructor(private api: ApiService,private loadingService: LoadingService,private toasterService: ToasterService) {
  }

  ngOnInit() {
    this.callUserLoginHistoryApi(this.userId,this.limit,this.offset);
  }


  private callUserLoginHistoryApi(userId: string,limit: number,offset: number) {
    this.loadingService.show();
    this.api.getUserLoginHistory(userId,limit,offset).subscribe({
      next: (res) => {
        this.tableListData = res.data;
        console.log(this.tableListData);
        this.loadingService.hide();
        // this.calculateTotalPages();
        // this.generateRowsPerPageOptions(res.data.totalCount);
      },
      error: (err) => {
        console.error(err);
        this.loadingService.hide();
        this.toasterService.error(err.error.errorDesc);

      }
    });
  }

  nextPage() {
    this.offset += this.limit;
    this.callUserLoginHistoryApi(this.userId, this.limit, this.offset);
  }

  previousPage() {
    if (this.offset - this.limit >= 0) {
      this.offset -= this.limit;
      this.callUserLoginHistoryApi(this.userId, this.limit, this.offset);
    }
  }

  updateLimit(limitValue: number) {
    this.limit = limitValue;
    this.offset = 0; // Reset offset when limit changes
    this.callUserLoginHistoryApi(this.userId, limitValue, this.offset);
  }
}
