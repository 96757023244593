import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  standalone: true,
  imports: [],
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
]})
export class ToggleComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() toggleId: string = 'toggle';
  @Input() isActive: boolean = false;
  @Input() disable: boolean = false

  @Output() toggleChange = new EventEmitter<boolean>();

  onChange = (value: boolean) => {};
  onTouched = () => {};

  toggleActive(): void {
    this.isActive = !this.isActive;
    this.onChange(this.isActive); // Notify Angular of change
    this.onTouched();
    this.toggleChange.emit(this.isActive);
  }

  writeValue(value: boolean): void {
    this.isActive = value;
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

}
