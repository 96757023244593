<div class="table-header">
  <div class="left">
    <div class="title">{{ title }}</div>
    <div class="subtitle">{{ subtitle }}</div>
  </div>

  @if (!disable) {
    <div class="right">

      @if (applyAllAllowed()) {
        <button class="secondary_button" (click)="cancelSelection()">Cancel</button>
        <button class="tertiary_button" (click)="applyEnteredValueForSelectedRows()">Apply All</button>
      } @else {
        @if (deleteAllowed()) {
          <button class="secondary_button" (click)="deleteTableData()"
                  style="display: flex;gap: 4px;align-items: flex-start;justify-content: center;height: 44px;">
            <img src="assets/icons/delete_icon.svg">
            Delete
          </button>
        }
        <div class="search-bar-with-suggestions">
          <div class="input-button-group">
            <input type="text" [placeholder]="searchBarConfig?.searchPlaceholder" class="search-input"
                   (input)="toggleSuggestionBox($event)" #searchBarInput />
            <button class="tertiary_button add-button" (click)="addRecord()">
              <span class="button-icon">+</span> {{ addButtonText }}
            </button>
          </div>
          <div class="search-results" *ngIf="showSuggestions">
            <table class="suggestions-table">
              <thead *ngIf="searchBarConfig.displayColumns?.length > 0 && suggestions.length > 0">
              <tr>
                <th *ngFor="let column of searchBarConfig?.displayColumns">{{ column.header }}</th>
              </tr>
              </thead>
              <tbody>
              <tr
                *ngFor="let result of suggestions"
                (click)="select(result)"
              >
                <td *ngFor="let column of searchBarConfig?.displayColumns">
                  <span [innerHTML]="result[column.key] | highlight : searchTerm"></span>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="result loading_results" *ngIf="loading">Loading...</div>
            <div class="result no_results" *ngIf="suggestions.length === 0 && !loading ">
              No results!
            </div>
          </div>
        </div>
      }
    </div>
  }
</div>

<div class="table_with_grid">
  <div class="table_container">
    @if (tableData.length == 0) {
      <app-no-data-found msg="{{noDataFoundErrorMsg}}"></app-no-data-found>
    } @else {
      <table>
        <thead>
        <tr>
          <th class="cb_th pinned">
            <input type="checkbox"
                   [checked]="isAllRecordsOfCurrentPageSelected()"
                   (change)="toggleAllRecordsOfCurrentPageSelections($event.target.checked)"
            />
          </th>
          @for (header of tableHeaders; track header.label) {
            <th [ngClass]="{
            'pinned': header.isPinned
          }">
              <div class="header_content_editable_table">
                @if (header.editable) {
                  <div class="header_checkbox">
                    <input type="checkbox" (change)="selectColumns(header.label)"
                           [checked]="isSelectedColumn(header.label)">
                  </div>
                }
                <div class="header_text_with_input_box">
                  <div class="table_header_text">{{ header.displayLabel }}</div>
                  @if (header.editable) {
                    <app-dynamic-input
                      #headerInput
                      [header]="header"
                      (updateValue)="updateColumnValue(header,$event)"
                      [disabled]="disable"
                    >
                    </app-dynamic-input>
                  }
                </div>

              </div>
            </th>
          }
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let row of tableData; let rowIndex = index"
          [ngClass]="{
          'selected-row': isSelected(row)
        }"
        >
          <td class="cb_td row-select-cb pinned">
            <input
              type="checkbox"
              [id]="'record_sl_no_' + row?.igmContainerId"
              [checked]="isSelected(row)"
              (change)="toggleSelection(row)"

            />
          </td>
          <td *ngFor="let header of tableHeaders" [ngClass]="{
        'pinned': header.isPinned
        }">
            @if (header.editable) {
              <app-dynamic-input
                #tableDataInput
                [header]="header"
                [row]="row"
                [value]="row[header.label]"
                (updateValue)="updateValue($event, rowIndex,header)"
                [disabled]="disable"
              >
              </app-dynamic-input>
            } @else {
              {{ row[header.label] }}
            }
          </td>
        </tr>
        </tbody>
      </table>
    }

  </div>
</div>
