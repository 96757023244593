import { Component, ComponentRef, EventEmitter, Input, Output, Type, ViewChild, ViewContainerRef } from "@angular/core";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";

@Component({
  selector: 'app-right-panel',
  standalone: true,
  imports: [
    SvgIconComponent
  ],
  templateUrl: './right-panel.component.html',
  styleUrl: './right-panel.component.scss'
})
export class RightPanelComponent {
  @Input() title: string | undefined;

  @Output() close = new EventEmitter<void>();
  @Output() refreshParent = new EventEmitter<void>();

  @ViewChild('rightPanelContent', { read: ViewContainerRef, static: true })
  rightPanelContent!: ViewContainerRef;

  onClose(params?: any) {
    this.close.emit(params);
  }

  loadComponent(component: Type<any>, data?: Partial<any>): ComponentRef<any> {
    this.rightPanelContent.clear(); // Clear any previously loaded content
    const componentRef = this.rightPanelContent.createComponent(component);

    // Pass the closeModal function to the dynamically loaded component
    if (componentRef.instance) {
      (componentRef.instance as any).closeRightPanel = this.onClose.bind(this);

      if (data) {
        Object.assign(componentRef.instance, data); // Pass the data to the component
      }
    }

    return componentRef;
  }
}
