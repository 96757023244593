<div
  class="container full_page_container login_system_bkg_cover centered_flex_container"
>
  <div
    class="login_card_component"
    *ngIf="!passwordUpdatedSuccessfully && !validatingToken"
  >
    @if (validToken) {
      <div class="feature_icon">
        <img src="assets/icons/key.svg">
      </div>
      <div class="heading text_align_center">
        <div class="header">Reset Password</div>
        <div class="sub_header">
          Enter a new password below to reset your password
        </div>
        <div class="passwordCriteria">
          <div class="title">Password Criteria</div>
          <div class="criteria">
            The password must contain at least
            <ul>
              <li
                [ngClass]="
                resetPasswordForm.get('newPassword')?.touched &&
                passwordContainsLowercase
                  ? 'valid'
                  : 'invalid'
              "
              >
                One lowercase character
              </li>
              <li
                [ngClass]="
                resetPasswordForm.get('newPassword')?.touched &&
                passwordContainsUppercase
                  ? 'valid'
                  : 'invalid'
              "
              >
                One uppercase character
              </li>
              <li
                [ngClass]="
                resetPasswordForm.get('newPassword')?.touched &&
                passwordContainsNumber
                  ? 'valid'
                  : 'invalid'
              "
              >
                One number
              </li>
              <li
                [ngClass]="
                resetPasswordForm.get('newPassword')?.touched &&
                passwordContainsSpecial
                  ? 'valid'
                  : 'invalid'
              "
              >
                One special character
              </li>
              <li
                [ngClass]="
                resetPasswordForm.get('newPassword')?.touched &&
                passwordMinLength
                  ? 'valid'
                  : 'invalid'
              "
              >
                8 characters minimum
              </li>
            </ul>
          </div>
        </div>
      </div>
      <form [formGroup]="resetPasswordForm" (ngSubmit)="requestResetPassword()">
        @if (otpRequired) {
          <div class="form-group">
            <label for="otp" class="required_field_label">OTP</label>
            <app-otp-input
              [ngClass]="{
            form_error:
            resetPasswordForm.get('otp')?.invalid &&
            resetPasswordForm.get('otp')?.touched &&
            resetPasswordForm.get('otp')?.errors
          }"
              id="otp"
              (otpChange)="onOtpChange($event)"
            ></app-otp-input>
            <div class="validation_error" *ngIf="
            resetPasswordForm.get('otp')?.invalid &&
            resetPasswordForm.get('otp')?.touched &&
            resetPasswordForm.get('otp')?.errors">
              <small *ngIf="resetPasswordForm.get('otp')?.errors?.['required']">OTP is required</small>
            </div>
            <div
              class="phone_login_options spaced_flex_container"
            >
              <div>
                <small class="countdown" *ngIf="!resendAllowed"
                >Resend OTP in {{ formatTime(countdown) }} seconds</small
                >
              </div>
              <div>
                <a [ngClass]="{ disabled: !resendAllowed }" (click)="resendOtp()"
                >Resend OTP</a
                >
              </div>
            </div>
          </div>
        }

        <div class="form-group">
          <label for="newPassword" class="required_field_label">New Password</label>
          <input
            id="newPassword"
            name="newPassword"
            formControlName="newPassword"
            (input)="validatePassword()"
            type="password"
            placeholder="Enter a new password"
            required
            [ngClass]="{
          form_error:
            resetPasswordForm.get('newPassword')?.touched && passwordInvalid
        }"
          />
          <div
            *ngIf="resetPasswordForm.get('newPassword')?.touched && passwordInvalid"
            class="validation_error"
          >
            <ng-container
              *ngIf="resetPasswordForm.get('newPassword')?.errors?.['required']"
            >
              <small>New password is required</small> <br />
            </ng-container>

            <ng-container
              *ngIf="!resetPasswordForm.get('newPassword')?.errors?.['required']"
            >
              <ng-container *ngIf="!passwordContainsLowercase">
                <small>At least one lowercase character is required</small><br />
              </ng-container>

              <ng-container *ngIf="!passwordContainsUppercase">
                <small>At least one uppercase character is required</small><br />
              </ng-container>

              <ng-container *ngIf="!passwordContainsNumber">
                <small>At least one number is required</small><br />
              </ng-container>

              <ng-container *ngIf="!passwordContainsSpecial">
                <small>At least one special character is required</small><br />
              </ng-container>

              <ng-container *ngIf="!passwordMinLength">
                <small>Minimum 8 characters are required</small><br />
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div class="form-group">
          <label for="confirmPassword" class="required_field_label"
          >Confirm Password</label
          >
          <input
            id="confirmPassword"
            name="confirmPassword"
            formControlName="confirmPassword"
            type="password"
            placeholder="Re-enter your password again"
            required
            [ngClass]="{
          form_error:
            resetPasswordForm.get('confirmPassword')?.touched &&
            (resetPasswordForm.get('confirmPassword')?.errors ||
              resetPasswordForm.hasError('passwordMismatch'))
        }"
          />
          <div
            class="validation_error"
            *ngIf="
          resetPasswordForm.get('confirmPassword')?.touched &&
          (resetPasswordForm.get('confirmPassword')?.errors ||
            resetPasswordForm.hasError('passwordMismatch'))
        "
          >
            <ng-container
              *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.['required']"
            >
              <small>Confirm password is required</small><br />
            </ng-container>

            <ng-container
              *ngIf="
            !resetPasswordForm.get('confirmPassword')?.errors?.['required'] && resetPasswordForm.hasError('passwordMismatch')
            "
            >
              <small> Passwords do not match.</small><br />
            </ng-container>
          </div>
        </div>

        <button type="submit" class="primary_button">Update</button>
      </form>
    } @else {
      <div class="heading text_align_center expired_heading">
        <div class="header error_color">Reset Password Link Expired !</div>
        <div class="sub_header">
          Sorry, your reset password link is no longer valid. Please request
          another one.
        </div>
      </div>
      <button class="primary_button" (click)="goToForgotPassword()">
        Request a new link
      </button>
    }
  </div>

  <div
    class="login_card_component reset_password_form"
    *ngIf="passwordUpdatedSuccessfully"
  >
    <img
      class="success_email_banner"
      src="/assets/images/reset_password_success.png"
      alt="Password Reset Success"
    />
    <div class="success_msg">You have successfully updated your password.</div>
    <button class="primary_button_short" (click)="goToLogin()">Login</button>
  </div>
</div>
