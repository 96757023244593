<div class="dynamic_input_container">
  @if (header?.fieldInfo.dataType === "picklist") {
    <app-autocomplete-dropdown
      [id]="header?.id"
      [placeholder]="header?.placeholder"
      [apiUrl]="header?.fieldInfo?.lookupDetails?.apiUrl"
      [selectFields]="header?.fieldInfo?.lookupDetails?.selectFields"
      [whereFields]="header?.fieldInfo?.lookupDetails?.whereFields"
      [limit]="header?.fieldInfo?.lookupDetails?.limit"
      [orderBy]="header?.fieldInfo?.lookupDetails.orderBy"
      [filterByKey]="header?.fieldInfo?.lookupDetails?.filterByKey"
      [bindLabel]="header?.fieldInfo?.lookupDetails?.bindLabel"
      [bindValue]="header?.fieldInfo?.lookupDetails?.bindValue"
      [displayColumns]="header?.fieldInfo?.lookupDetails?.displayColumns"
      [disable]="disabled"
      [preSelectedValue]="value"
      (suggestionSelected)="updateValueEmitter($event)"
    >
    </app-autocomplete-dropdown>
  } @else if (header?.fieldInfo?.dataType === "select") {
    <select
      (blur)="updateValueEmitter($event)"
      (keydown.enter)="updateValueEmitter($event)"
      [(ngModel)]="value"
      (change)="updateValueEmitter($event)"
      [disabled]="disabled"
    >
      <option value="" disabled selected>Select</option>
      @for (option of header?.fieldInfo?.options; track option.value) {
        <option [value]="option?.value">{{ option?.label }}</option>
      }

    </select>
  } @else if (header?.fieldInfo?.dataType === "boolean") {
    <select
      (blur)="updateValueEmitter($event)"
      (keydown.enter)="updateValueEmitter($event)"
      [(ngModel)]="value"
      (change)="updateValueEmitter($event)"
      [disabled]='disabled'
    >
      <option value="" disabled selected>Select</option>
      <option [value]="true">Yes</option>
      <option [value]="false">No</option>
    </select>
  } @else {
    <input
      [(ngModel)]="value"
      [type]="header?.fieldInfo?.dataType"
      [placeholder]="header?.placeholder"
      (blur)="updateValueEmitter($event)"
      (keydown.enter)="updateValueEmitter($event)"
      [disabled]="disabled"
    >
  }
</div>
