import { Component, OnInit } from '@angular/core';

export interface FormLayoutConfig {
    [key: string]: {
        gridTemplateAreas: string[];
        fieldInfoMap: { [key: string]: FieldProperties},
        trailerAlwaysSearchable?: boolean,
        containerSearchable?: boolean,
        mandateThroughJO?: boolean,
        containerFilters?: any[],
        containerEndpoint?: string,
        trailerSearchEndpoint:string
        trailerFilters?: any[],
        trailerSelectFields: string[]; 
      };
}

export interface FieldProperties {
  required: boolean;
  type?: string;
}

export const trailerMasterSelectFields = ['id', 'trailerNumber', 'vendorId.id as vendorId', 'vendorId.name as transporterName', 'vehicleTypeId.id as vehicleTypeId', 'vehicleTypeId.generalPurposeType as vehicleTypeValue'];
export const trailerInventorySelectFields = ['id', 'trailerNumber', 'transporterName', 'vehicleTypeId.id as vehicleTypeId', 'vehicleTypeId.generalPurposeType as vehicleTypeValue'];

export const formLayoutConfig: FormLayoutConfig = {
    "IMPORT_LOADED_CONTAINER": {
      gridTemplateAreas: [
        "'containerNo accountHolderId lineAgentId vesselId'",
        "'isoCode contSizeType lineSealNo physicalSealNo'",
        "'tareWeight grossWeight eirNo eirDate'",
        "'scanTypeGroup scanStatus hazardous odc'",
        "'reeferPlugin tempGroup fromLocationId condition'",
        "'remarks remarks . .'"
      ],
      fieldInfoMap: {
        containerNo: { required: true},
        accountHolderId: { required: true, type: 'autocomplete-dropdown'},
        lineAgentId: { required: true, type: 'autocomplete-dropdown'},
        vesselId: { required: false, type: 'autocomplete-dropdown'},
        isoCode: {required: true, type: 'autocomplete-dropdown'},
        containerSize: {required: true},
        containerType: {required: true},
        lineSealNo: {required: true},
        physicalSealNo: {required: true},
        tareWeight: {required: true},
        grossWeight: {required: false},
        eirNo: {required: true},
        eirDate: {required: true},
        scanType: {required: true},
        scanStatus: {required: true},
        fromLocationId: {required: false, type: 'autocomplete-dropdown'},
        condition: {required: true}
      },
      containerEndpoint: "v1/igm-containers/list",
      trailerSearchEndpoint: "v1/trailers/list",
      trailerSelectFields: trailerMasterSelectFields,
      containerSearchable: true
    },
    "IMPORT_EMPTY_CONTAINER": {
      gridTemplateAreas: [
        "'containerNo accountHolderId lineAgentId lineSealNo'",
        "'isoCode contSizeType tareWeight grossWeight'",
        "'fromLocationId condition . .'",
        "'remarks remarks . .'"
      ],
      fieldInfoMap: {
        containerNo: { required: true},
        accountHolderId: { required: true, type: 'autocomplete-dropdown'},
        lineAgentId: { required: true, type: 'autocomplete-dropdown'},
        vesselId: { required: false, type: 'autocomplete-dropdown'},
        isoCode: {required: true, type: 'autocomplete-dropdown'},
        containerSize: {required: true},
        containerType: {required: true},
        lineSealNo: {required: false},
        physicalSealNo: {required: false},
        tareWeight: {required: true},
        grossWeight: {required: false},
        eirNo: {required: false},
        eirDate: {required: false},
        scanType: {required: false},
        scanStatus: {required: false},
        fromLocationId: {required: false, type: 'autocomplete-dropdown'},
        condition: {required: true}
      },
      trailerSearchEndpoint: "v1/trailers/list",
      trailerSelectFields: trailerMasterSelectFields,
      containerSearchable: false,
      containerFilters: [
        {
          name: "loaded",
          alias: "loaded",
          operator: "EQ",
          value: [false]
        },
      ]
    },
    "SCAN_AREA_RETURN": {
      gridTemplateAreas: [
        "'containerNo accountHolderId isoCode contSizeType'",
        "'lineSealNo physicalSealNo tareWeight grossWeight'",
        "'scanTypeGroup scanStatus hazardous odc'",
        "'reeferPlugin tempGroup condition fromLocationId'",
        "'remarks remarks . .'"
      ],
      fieldInfoMap: {
        containerNo: { required: true},
        accountHolderId: { required: true, type: 'autocomplete-dropdown'},
        lineAgentId: { required: true, type: 'autocomplete-dropdown'},
        vesselId: { required: false, type: 'autocomplete-dropdown'},
        isoCode: {required: true, type: 'autocomplete-dropdown'},
        containerSize: {required: true},
        containerType: {required: true},
        lineSealNo: {required: true},
        physicalSealNo: {required: false},
        tareWeight: {required: false},
        grossWeight: {required: false},
        eirNo: {required: false},
        eirDate: {required: false},
        scanType: {required: false},
        scanStatus: {required: false},
        fromLocationId: {required: false, type: 'autocomplete-dropdown'},
        condition: {required: true}
      },
      containerSearchable: true,
      containerEndpoint: "v1/containers/list",
      trailerAlwaysSearchable: true,
      trailerSearchEndpoint: "v1/inventory/trailers/list",
      containerFilters: [
        {
          name: "lastActivity",
          alias: "lastActivity",
          operator: "EQ",
          value: ["TRANSFER_TO_SCAN_AREA"]
        }
      ],
      trailerFilters: [
        {
          name: "lastActivity",
          alias: "lastActivity",
          operator: "EQ",
          value: ["TRANSFER_TO_SCAN_AREA"]
        }
      ],
      trailerSelectFields: trailerInventorySelectFields,
    },
    "EXPORT_BUFFER_CONTAINER": {
      gridTemplateAreas: [
        "'containerNo accountHolderId lineAgentId lineSealNo'",
        "'isoCode contSizeType tareWeight grossWeight'",
        "'fromLocationId condition . .'",
        "'remarks remarks . .'"
      ],
      fieldInfoMap: {
        containerNo: { required: true},
        accountHolderId: { required: true, type: 'autocomplete-dropdown'},
        lineAgentId: { required: true, type: 'autocomplete-dropdown'},
        vesselId: { required: false, type: 'autocomplete-dropdown'},
        isoCode: {required: true, type: 'autocomplete-dropdown'},
        containerSize: {required: true},
        containerType: {required: true},
        lineSealNo: {required: false},
        physicalSealNo: {required: false},
        tareWeight: {required: true},
        grossWeight: {required: false},
        eirNo: {required: false},
        eirDate: {required: false},
        scanType: {required: false},
        scanStatus: {required: false},
        fromLocationId: {required: false, type: 'autocomplete-dropdown'},
        condition: {required: true}
      },
      trailerSearchEndpoint: "v1/trailers/list",
      trailerSelectFields: trailerMasterSelectFields,
    },
    "DEFAULT": {
      gridTemplateAreas: [
        "'containerNo accountHolderId lineAgentId lineSealNo'",
        "'isoCode contSizeType tareWeight grossWeight'",
        "'fromLocationId condition . .'",
        "'remarks remarks . .'"
      ],
      fieldInfoMap: {
        containerNo: { required: true},
        accountHolderId: { required: true, type: 'autocomplete-dropdown'},
        lineAgentId: { required: true, type: 'autocomplete-dropdown'},
        vesselId: { required: false, type: 'autocomplete-dropdown'},
        isoCode: {required: true, type: 'autocomplete-dropdown'},
        containerSize: {required: true},
        containerType: {required: true},
        lineSealNo: {required: false},
        physicalSealNo: {required: false},
        tareWeight: {required: true},
        grossWeight: {required: false},
        eirNo: {required: false},
        eirDate: {required: false},
        scanType: {required: false},
        scanStatus: {required: false},
        fromLocationId: {required: false, type: 'autocomplete-dropdown'},
        condition: {required: true}
      },
      trailerSearchEndpoint: "v1/trailers/list",
      trailerSelectFields: trailerMasterSelectFields,
    }
  };