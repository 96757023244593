
<div class="toggle-group">
    <label [for]="toggleId" class="toggle-label">{{ label }}</label>
    <div class="toggle-actions">
        <div class="toggle-wrapper">
          <label class="toggle">
            <input type="checkbox" [checked]="isActive" (change)="toggleActive()" [disabled]="disable"/>
            <span class="toggle-slider"></span>
          </label>
        </div>
      </div>
</div>
