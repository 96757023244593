import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function AutocompleteDropdownComponentValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (typeof value === 'string' && value) {
        return null
      }
      if (typeof value === 'object' && value && (value.key || value.value)) {
        return null;
      }
      return { required: true };
    };
  }
  