<header class="page_header">
  <div class="header_content">
    <div class="text_and_supporting_text">
      <div class="page_title">IGM Desk</div>
      <div class="page_desc">View vessel and container information</div>
    </div>
    <div class="action_btn">
      <button class="add_new_btn" (click)="uploadFile()">
        <img src="assets/icons/plus_icon.svg" height="20" width="20">Upload File
      </button>
    </div>
  </div>
</header>

<div class="search_bar_action_btns">
  <div class="container">
    <div class="search_bar">
      <div class="search_container">
        <input
          type="text"
          name="search_table"
          id="search_table"
          [(ngModel)]="searchTerm"
          placeholder="Search by name or email"
          (focus)="focusSearch = true"
          (focusout)="focusSearch = false"
          (keyup)="handleTableSearch($event)"
        >
        <img src="assets/icons/search_icon.svg" class="search_icon">
      </div>
    </div>

    <div class="filter_btn">
      <button (click)="filter()">
        <img src="assets/icons/filter.svg">
        Filters
      </button>
    </div>
  </div>
</div>

<div class="tos-table-template">
  <div class="table-container">
    <ng-container *ngIf="!tableLoaded">
      <app-no-data
        [msg]="
            'No IGM Desk data are available in the system. Please click on Add New Item button to create one.'
          "
      ></app-no-data>
    </ng-container>
    <ng-container
      *ngIf="
          tableLoaded &&
          tableListData.records?.length > 0
        "
    >
      <table>
        <thead>
        <tr>
          <th class="cb_th select-all-cb">
            <input
              type="checkbox"
              id="select_all_users"
              [checked]="isAllRecordsOfCurrentPageSelected()"
              (change)="
                    toggleAllRecordsOfCurrentPageSelections($event.target.checked)
                  "
            />
          </th>
          <!--            <th class="sl_no_th">Sl No.</th>-->
          <th
            class="containerNo_th sortable"
            [ngClass]="{ sorted: sortColumn === 'containerNo' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'containerNo')"
          >
            <div class="icon_cover" (click)="sortData('containerNo')">
              Container Number
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>
          <th
            class="vesselId_th sortable"
            [ngClass]="{ sorted: sortColumn === 'vesselId' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'vesselId')"
          >
            <div class="icon_cover" (click)="sortData('vesselId')">
              Vessel ID
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="viaNo_th sortable"
            [ngClass]="{ sorted: sortColumn === 'viaNo' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'viaNo')"
          >
            <div class="icon_cover" (click)="sortData('viaNo')">
              Via No
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="containerSize_th sortable"
            [ngClass]="{ sorted: sortColumn === 'containerSize' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'containerSize')"
          >
            <div class="icon_cover" (click)="sortData('containerSize')">
              Container Size
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="containerType_th sortable"
            [ngClass]="{ sorted: sortColumn === 'containerType' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'containerType')"
          >
            <div class="icon_cover" (click)="sortData('containerType')">
              Container Type
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="createdDate_th sortable"
            [ngClass]="{ sorted: sortColumn === 'createdDate' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'createdDate')"
          >
            <div class="icon_cover" (click)="sortData('createdDate')">
              Created Date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="hazardous_th sortable"
            [ngClass]="{ sorted: sortColumn === 'hazardous' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'hazardous')"
          >
            <div class="icon_cover" (click)="sortData('hazardous')">
              Hazardous
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="reeferPlugin_th sortable"
            [ngClass]="{ sorted: sortColumn === 'reeferPlugin' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'reeferPlugin')"
          >
            <div class="icon_cover" (click)="sortData('reeferPlugin')">
              Reefer Plugin
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="odc_th sortable"
            [ngClass]="{ sorted: sortColumn === 'odc' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'odc')"
          >
            <div class="icon_cover" (click)="sortData('odc')">
              ODC
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>

          <th
            class="scanType_th sortable"
            [ngClass]="{ sorted: sortColumn === 'scanType' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'scanType')"
          >
            <div class="icon_cover" (click)="sortData('scanType')">
              Scan Type
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>


          <th
            class="modifiedDate_th sortable"
            [ngClass]="{ sorted: sortColumn === 'modifiedDate' }"
            mwlResizable
            (resizing)="onResizeEnd($event,'modifiedDate')"
          >
            <div class="icon_cover" (click)="sortData('modifiedDate')">
              Modified Date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </th>
          <th class="table_menu_th">
            <div class="icon_cover plus_icon">
              <app-svg-icon
                [path]="'assets/icons/plus_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let record of tableListData.records; let i = index"
          [ngClass]="isSelected(record) ? 'selected_row' : ''"
        >
          <td class="cb_td row-select-cb">
            <input
              type="checkbox"
              [id]="'record_sl_no_' + record.id"
              [checked]="isSelected(record)"
              (change)="toggleSelection(record)"
            />
          </td>
          <td class="containerNo_td" [title]="record.containerNo">
            <span [innerHTML]="record.containerNo | highlight : searchTerm"></span>
          </td>
          <td class="vesselId_td" [title]="record.vesselId">
            <span [innerHTML]="record.vesselId | highlight : searchTerm"></span>
          </td>

          <td class="viaNo_td" [title]="record.viaNo">
            <span [innerHTML]="record.viaNo | highlight : searchTerm"></span>
          </td>

          <td class="containerSize_td" [title]="record.containerSize">
            <span>{{ record.containerSize }}</span>
          </td>

          <td class="containerType_td" [title]="record.containerType">
            <span>{{ record.containerType }}</span>
          </td>

          <td class="createdDate_td" [title]="record.createdDate">
            <span>{{ record.createdDate | date : "dd/MM/yy hh:mm a" }}</span>
          </td>

          <td class="hazardous_td" [title]="record.hazardous">
            <div
              class="icon_cover"
              [ngClass]="record.hazardous ? 'admin' : 'not_admin'"
            >
              <div class="badge_locked">
                <app-svg-icon
                  [path]="'assets/icons/shield_icon.svg'"
                ></app-svg-icon>
                {{ record.hazardous ? "Yes" : "No" }}
              </div>
            </div>
          </td>

          <td class="reeferPlugin_td" [title]="record.reeferPlugin">
            <div
              class="icon_cover"
              [ngClass]="record.reeferPlugin ? 'admin' : 'not_admin'"
            >
              <div class="badge_locked">
                <app-svg-icon
                  [path]="'assets/icons/shield_icon.svg'"
                ></app-svg-icon>
                {{ record.reeferPlugin ? "Yes" : "No" }}
              </div>
            </div>
          </td>

          <td class="odc_td" [title]="record.odc">
            <div
              class="icon_cover"
              [ngClass]="record.odc ? 'admin' : 'not_admin'"
            >
              <div class="badge_locked">
                <app-svg-icon
                  [path]="'assets/icons/shield_icon.svg'"
                ></app-svg-icon>
                {{ record.odc ? "Yes" : "No" }}
              </div>
            </div>
          </td>

          <td class="scanType_td" [title]="record.scanType">
            <span>{{ record.scanType }}</span>
          </td>
          <td class="modifiedDate_td">
            {{ record.modifiedDate | date : "dd/MM/yy hh:mm a" }}
          </td>
          <td class="table_menu_td">
            <div class="menu_component">
              <app-table-menu
                [options]="menuOptions"
                [isOpen]="openMenuIndex === i"
                (toggle)="handleMenuToggle(i)"
                (optionSelected)="handleOptionSelected(record, $event)"
                [tableRowData]="record"
              >
              </app-table-menu>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container
      *ngIf="
          tableLoaded &&
          tableListData.records?.length === 0
        "
    >
      <app-no-data-found [msg]="'No results found !'"></app-no-data-found>
    </ng-container>
  </div>

  <div class="pagination" [hidden]="tableListData.totalCount === 0">

    <div class="button_wrapper_left">
      <button  (click)="previousPage()"><img src="assets/icons/left-arrow.svg"> Previous</button>
    </div>
    <ng-container *ngIf="totalTablePages" class="page_numbers">
      <div
        *ngFor="let page of getDisplayedPages()"
        (click)="page !== '...' ? goToPage(page) : null"
        [class.page_number]="page !== '...'"
        [class.ellipsis]="page === '...'"
        [class.active]="currentTablePage === page"
      >
        {{ page }}
      </div>
    </ng-container>

    <div class="button_wrapper_right">
      <div class="rows_per_page">
        Rows per page
        <select (change)="updateRowsPerPage($event.target.value)" class="select_dropdown">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <button (click)="nextPage()">Next <img src="assets/icons/right-arrow.svg"></button>
    </div>
  </div>
</div>
