import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { GateInRequest } from "../models/gate-in-request.interface";
import { TableListRequest } from "../models/table-list-request.interface";

const operationsEndPoint = "v1/operations";

const gateInEndPoint = "v1/operations/gate-in";
const cargoItemsEndPoint = "v1/import-cargo-items";
const trailerInventoryEndPoint = "v1/inventory/trailers";
const containerInventoryEndPoint = "v1/containers";
const igmContainersEndPoint = "v1/igm-containers";

@Injectable({
    providedIn: 'root',  // Ensures the service is available app-wide
  })
export class OperationService {

    private baseUrl: string = environment.apiBaseUrl;

    constructor(private http: HttpClient) {
    }

    submitGateInRequest(gateInRequest: GateInRequest) {
      return this.http.post(
        `${this.baseUrl}/${gateInEndPoint}`,
        gateInRequest
      );
    }

    fetchTrailerAndContainerByTrailerId(trailerId: string) {
      return this.http.get(`${this.baseUrl}/${trailerInventoryEndPoint}/${trailerId}?includeContainers=true`);
    }

    fetchContainerById(containerId: string) {
      return this.http.get(`${this.baseUrl}/${containerInventoryEndPoint}/${containerId}`);
    }

    submitItemFilingForm(cargoItem: any) {
            return this.http.post(
              `${this.baseUrl}/${cargoItemsEndPoint}`,
              cargoItem
            );
        }

      updateItemFilingForm(cargoItemId: string,cargoItem: any) {
        return this.http.put(
          `${this.baseUrl}/${cargoItemsEndPoint}/${cargoItemId}`,
          cargoItem
        );
      }

      getItemFilingById(itemFilingId: string) {
        return this.http.get(`${this.baseUrl}/${cargoItemsEndPoint}/${itemFilingId}?withContainers=true`);
      }

      getItemFilingList(tableListRequest: TableListRequest) {
        return this.http.post(`${this.baseUrl}/${cargoItemsEndPoint}/list`, tableListRequest);
      }

      getIgmDeskList(tableListRequest: TableListRequest) {
        return this.http.post(`${this.baseUrl}/${igmContainersEndPoint}/list`, tableListRequest);
      }
}

