import { Component, ElementRef, EventEmitter, input, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { AutocompleteDropdownComponent } from "../../autocomplete-dropdown/autocomplete-dropdown.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EditableTableHeader } from "../../../models/editable-table-header.interface";
import { ToggleComponent } from "../../common/toggle/toggle.component";

@Component({
  selector: 'app-dynamic-input',
  standalone: true,
  imports: [
    AutocompleteDropdownComponent,
    FormsModule,
    ReactiveFormsModule,
    ToggleComponent
  ],
  templateUrl: './dynamic-input.component.html',
  styleUrl: './dynamic-input.component.scss'
})
export class DynamicInputComponent {
  @Input() header!: EditableTableHeader;
  @Input() value: string = '' ;
  @Input() row!: number;
  @Output() updateValue = new EventEmitter<Event>();
  @Input() disabled: boolean = false;
  @ViewChild(AutocompleteDropdownComponent) autocompleteDropdown!: AutocompleteDropdownComponent;

  ngOnInit(){
    if(this.header.fieldInfo?.dataType === 'date'){
      this.value = this.value?.split('T')[0];
    }
  }

  updateValueEmitter(event: Event) {
    this.updateValue.emit(event);
  }
}
