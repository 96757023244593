<div class="item-selector-container">
    <!-- Left Pane -->
    <div class="left-pane-container">
      <header class="items-header">
        <div class="items-title"><span>Cargo Items</span></div>
        <div class="search-section">
            <input
                type="search"
                id="searchInput"
                class="search-input"
                [(ngModel)]="searchQuery"
                name="search"
                placeholder="Search items"
                aria-label="Search items"
              />
            <button type="button" class="add-button" (click)="addItem()">
                <app-svg-icon class="image-icon"
                [path]="'assets/icons/add_plus_icon.svg'"></app-svg-icon>
                <span>Add</span>
            </button>
            <app-svg-icon class="search-icon" [path]="'assets/icons/search_icon.svg'"></app-svg-icon>
        </div>
      </header>
  
      <div class="item-list">
        <div
          class="item-row"
          *ngFor="let item of addedItems"
          (click)="selectItem(item)"
          [class.selected]="selectedItem?.id === item.id"
          tabindex="0"
          role="button"
        >
            <div class="checkbox-wrapper">
                <input type="checkbox" id="itemCheckbox" class="item-checkbox" aria-label="Select item"/>
            </div>
            <div class="item-info">
                <span class="item-name">{{ item.name }}</span>
            </div>
        </div>
      </div>
    </div>
  
    <!-- Right Pane -->
    <div class="right-pane-container">
        <ng-template #rightPaneContent></ng-template>
    </div>
</div>
  